import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Button, TextField, Stack, Subheading, Icon, Popover, ActionList, TextStyle } from "@shopify/polaris";
import { AddCodeMajor, DragHandleMinor, SettingsMinor, TextMajor, AddMajor } from "@shopify/polaris-icons";
import DraggableResourceList from "../DraggableResourceList";
import { useReportTemplate } from "../../TemplateContext";
import ColumnModal from "./ColumnModal";
import "./ActiveFieldList.scss";

const arraySlice = (list, index, replacement = null) => {
  const newList = list.slice();
  if (replacement) {
    newList.splice(index, 1, replacement);
  } else {
    newList.splice(index, 1);
  }
  return newList;
};

const capitalizeString = (string) => {
  if (!string || !(string.length >= 1)) return null;
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

function columnIndexToExcel(index) {
  return (index < 26 ? "" : columnIndexToExcel(Math.floor(index / 26 - 1))) + String.fromCharCode(65 + (index % 26));
}

function ActiveFieldItem({ field, objectFields, onChange, onReorder, index, count }) {
  const [liquidEditorOpen, setLiquidEditorOpen] = useState(false);
  const makeLiquidEditorOpen = useCallback(() => setLiquidEditorOpen(true), []);
  const clearLiquidEditorOpen = useCallback(() => setLiquidEditorOpen(false), []);

  const objectField = useMemo(() => objectFields.find((localField) => localField.id === field.id), [field.id, objectFields]);
  const longPath = useMemo(
    () => (objectField ? [...objectField.displayPath, objectField.name].join(" > ") : field.id),
    [field.id, objectField]
  );
  const crossString = useMemo(
    () => (objectField?.cross?.length > 0 ? ` (${capitalizeString(objectField.cross[0])})` : null),
    [objectField?.cross]
  );

  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(() => setPopoverActive(!popoverActive), [popoverActive]);
  const onRemove = useCallback(() => onChange(null), [onChange]);
  const onRename = useCallback((newName) => onChange({ ...field, name: newName }), [field, onChange]);

  return (
    <>
      <Stack vertical spacing="extraTight">
        <Stack.Item>
          <Subheading>{longPath}</Subheading>
        </Stack.Item>
        <Stack wrap={false} spacing="extraTight" alignment="center">
          <Stack.Item>
            <TextStyle variation="code">{columnIndexToExcel(index)}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <Icon source={DragHandleMinor} color="subdued" />
          </Stack.Item>
          <Stack.Item fill>
            <TextField
              label={(objectField && `${objectField.name} ${crossString || ""}`) || "Custom Field"}
              labelHidden
              value={field.name}
              onChange={onRename}
            />
          </Stack.Item>
          {field.text != null || field.liquid != null ? (
            <Stack.Item>
              <Button onClick={makeLiquidEditorOpen} icon={field.text != null ? TextMajor : AddCodeMajor} label="Edit Code" />
            </Stack.Item>
          ) : null}
          <Stack.Item>
            <Popover
              activator={<Button onClick={togglePopoverActive} icon={SettingsMinor} label="Field Actions" />}
              active={popoverActive}
              onClose={togglePopoverActive}
            >
              <ActionList
                items={[
                  {
                    content: "Remove Field",
                    onAction: onRemove,
                    destructive: true,
                  },
                  {
                    content: "Move Left",
                    disabled: index <= 0,
                    onAction: () => {
                      onReorder(index, index - 1);
                    },
                  },
                  {
                    content: "Move Right",
                    disabled: index >= count - 1,
                    onAction: () => {
                      onReorder(index, index + 1);
                    },
                  },
                ]}
              />
            </Popover>
          </Stack.Item>
        </Stack>
      </Stack>
      <ColumnModal reportField={field} open={liquidEditorOpen} onClose={clearLiquidEditorOpen} />
    </>
  );
}

function ActiveFieldList({ scrollToBottom, lockPosition }) {
  const [{ reportFields }, dispatch, { objectFields }] = useReportTemplate();

  const lastRenderCountRef = useRef(null);
  useEffect(() => {
    if (reportFields.length === lastRenderCountRef.current + 1) {
      scrollToBottom?.();
    }
    lastRenderCountRef.current = reportFields.length;
  }, [reportFields.length, scrollToBottom]);

  const setReportFields = useCallback((value) => dispatch({ type: "merge", value: { reportFields: value } }), [dispatch]);

  const onReorder = useCallback(
    ({ from, to }) => {
      const result = Array.from(reportFields);
      const removed = result.splice(from, 1)[0];
      result.splice(to, 0, removed);
      setReportFields(result);
    },
    [reportFields, setReportFields]
  );

  const onBeforeDrag = lockPosition;

  const moveColumnToDestination = useCallback(
    (sourceIndex, destinationIndex) => {
      const newReportFields = reportFields.slice();
      const [reportField] = newReportFields.splice(sourceIndex, 1);
      newReportFields.splice(destinationIndex, 0, reportField);
      setReportFields(newReportFields);
    },
    [reportFields, setReportFields]
  );

  return (
    <Stack vertical>
      <Subheading>Selected Fields ({reportFields.length})</Subheading>
      <p>
        <Stack alignment="center" spacing="extraTight">
          <Stack.Item>Add new columns using</Stack.Item>
          <Stack.Item>
            <Icon source={AddMajor} color="base" />
          </Stack.Item>
          <Stack.Item>in the Shopify Field Browser</Stack.Item>
        </Stack>
      </p>
      {reportFields.length > 0 ? (
        <div className="active-field-list__field-item-list">
          <DraggableResourceList
            items={reportFields.map((field, index) => ({
              id: field.id,
              item: (
                <div className="active-field-list__field-item">
                  <ActiveFieldItem
                    key={field.id}
                    field={field}
                    onChange={(newField) => setReportFields(arraySlice(reportFields, index, newField))}
                    onReorder={moveColumnToDestination}
                    objectFields={objectFields}
                    index={index}
                    count={reportFields.length}
                  />
                </div>
              ),
            }))}
            onReorder={onReorder}
            onBeforeDrag={onBeforeDrag}
          />
        </div>
      ) : (
        <>
          <p>Your report is currently empty.</p>
          <p>Add new fields from the Shopify Field Browser, or reach out to support if you need help.</p>
        </>
      )}
    </Stack>
  );
}

export default ActiveFieldList;
