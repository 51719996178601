import React, { useEffect, useState, useMemo, useCallback } from "react";
import { TextStyle, Button, Stack, Icon, Subheading, Tooltip, OptionList, Popover } from "@shopify/polaris";
import {
  ClockMinor,
  FolderPlusMajor,
  FolderMinusMajor,
  FolderMajor,
  AddCodeMajor,
  FilterMajor,
  DeleteMinor,
  AddMajor,
  MobileHamburgerMajor,
  InfoMinor,
} from "@shopify/polaris-icons";

import { FeatureGate, UpgradeBadge, useFeatureContext } from "admin-frontend";
import { useReportTemplate } from "../../TemplateContext";
import { useFieldListContext } from "./FieldListContext";
import { LIQUID_FILTER_OPERATORS } from "./values";

import "./FieldList.scss";

const REDUCED_FIELD_LIST_SEARCH_OBJECT_ID = Symbol("REDUCED_FIELD_LIST_SEARCH_OBJECT_ID");
const MIN_WIDTH_FOR_FIELD_CATEGORY_BROWSER = 400;

const capitalizeString = (string) => {
  if (!string || !(string.length >= 1)) return null;
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

const sortFields = (object1, object2) => {
  let toReturn = 0;
  // Weight shopify query fields the highest
  if (object1.querySource === "both" || object1.querySource === "shopify") {
    toReturn -= 1000;
  }
  if (object1.querySource === object2.querySource) {
    toReturn = 0;
  }
  // Fields closer to the top are weighted higher
  toReturn += (object1.displayPath.length - object2.displayPath.length) * 100;

  // Line Items and Transactions are the highest priority for remaining fields.
  if (object1.displayParent.match(/order-line_items$/) && !object2.displayParent.match(/order-line_items$/)) {
    toReturn -= 1;
  }
  if (object1.displayParent.match(/order-transactions$/) && !object2.displayParent.match(/order-transactions$/)) {
    toReturn -= 1;
  }
  return toReturn;
};

const fieldContains = ({ name: fieldName, id, displayPath, shopifyData }, text) => {
  const words = text.toLowerCase().split(/\W+/).filter(Boolean);
  return words.every(
    (word) =>
      String(fieldName || "").includes(word) ||
      String(id || "").includes(word) ||
      displayPath.slice(1).some((part) =>
        String(part || "")
          .toLowerCase()
          .includes(word)
      ) ||
      String(shopifyData).toLowerCase().includes(words)
  );
};

export const traverseObject = (obj = {}, keys = [], separator = null) => {
  if (obj == null) {
    return null;
  }

  const values = keys
    .reduce(
      (last, key) =>
        last
          .flatMap((el) => {
            const next = el?.[key];
            return Array.isArray(next) ? next : [next];
          })
          .filter((n) => n != null),
      [obj]
    )
    .filter((n) => typeof n === "string" || typeof n === "number" || typeof n === "boolean" || n === null);

  if (separator != null) {
    return [values.join(separator)];
  }
  return values;
};

function objectBrowserSubtreeItems({
  fieldId,
  searchObject,
  objectFields,
  openRelations,
  activeRelations,
  indent = 0,
  isMissingMetafieldFeature = false,
}) {
  const subTreeField = objectFields.find((field) => field.id === fieldId);
  const children = objectFields.filter(
    (field) =>
      field.displayParent === fieldId && field.relation && objectFields.some((subField) => subField.displayParent === field.id)
  );
  const passivelyOpen = activeRelations.some((activeField) => activeField.id === subTreeField?.id);

  const open = openRelations.has(fieldId);

  const upgradeRequired = /metafields|transactions|risk/.test(subTreeField?.id) && isMissingMetafieldFeature;

  let icon = FolderMajor;
  if (children.length) {
    icon = open ? FolderMinusMajor : FolderPlusMajor;
  }

  return [
    {
      label: (
        <FeatureGate featureName="metafields" featureValue={1}>
          <Stack spacing="extraTight" wrap={false}>
            <Stack.Item>{"".padStart(indent * 4, "\u00a0")}</Stack.Item>
            <Stack.Item>
              <Icon source={icon} color={passivelyOpen ? "primary" : "base"} />
            </Stack.Item>
            <Stack.Item fill>
              <Stack spacing="extraTight">
                <Stack.Item>{subTreeField?.name || fieldId}</Stack.Item>
                <Stack.Item>{upgradeRequired && <UpgradeBadge asPopover />}</Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </FeatureGate>
      ),
      value: fieldId,
      active: searchObject === fieldId,
    },
    ...(open
      ? children.flatMap((field) =>
        objectBrowserSubtreeItems({
          fieldId: field.id,
          searchObject,
          objectFields,
          openRelations,
          activeRelations,
          indent: indent + 1,
          isMissingMetafieldFeature,
        })
      )
      : []),
  ];
}

function ObjectBrowser({
  // reducedFieldList,
  searchObject,
  topLevelItem,
  objectFields,
  activeRelations,
  setSearchObject,
  additionalRelationItems,
}) {
  const isMissingMetafieldFeature = useFeatureContext({ featureName: "metafields", featureValue: true });
  const [openRelations, setOpenRelations] = useState(new Set([topLevelItem]));

  const objectFieldMap = useMemo(() => new Map(objectFields.map((field) => [field.id, field])), [objectFields]);

  const onChange = useCallback(
    (newActiveRelation) => {
      const [newSearchObject] = newActiveRelation;

      const newOpenRelations = new Set(openRelations);

      if (searchObject === newSearchObject) {
        if (openRelations.has(searchObject)) {
          newOpenRelations.delete(searchObject);
        } else {
          newOpenRelations.add(searchObject);
        }
      } else {
        newOpenRelations.delete(searchObject);
        newOpenRelations.add(newSearchObject);

        let nextField = objectFieldMap.get(newSearchObject);

        while (nextField && nextField.displayParent) {
          nextField = objectFieldMap.get(nextField.displayParent);
          if (nextField) {
            newOpenRelations.add(nextField.id);
          }
        }
      }

      setOpenRelations(newOpenRelations);

      if (newSearchObject) {
        setSearchObject(newSearchObject);
      }
    },
    [objectFieldMap, openRelations, searchObject, setSearchObject]
  );

  const listItems = useMemo(
    () => [
      ...objectBrowserSubtreeItems({
        fieldId: topLevelItem,
        searchObject,
        objectFields,
        openRelations,
        activeRelations,
        isMissingMetafieldFeature,
      })
    ],
    [activeRelations, isMissingMetafieldFeature, objectFields, openRelations, searchObject, topLevelItem]
  );

  const relationItems = useMemo(
    () => [
      ...(additionalRelationItems
        ? additionalRelationItems.flatMap((relationItem) =>
          objectBrowserSubtreeItems({
            fieldId: relationItem,
            searchObject,
            objectFields,
            openRelations,
            activeRelations,
            isMissingMetafieldFeature,
          })
        )
        : []),
    ],
    [activeRelations, additionalRelationItems, isMissingMetafieldFeature, objectFields, openRelations, searchObject]
  );

  const relationSections = (
    additionalRelationItems.length > 0 ?
    [
      {
        title: "Additional Object Filters",
        options: relationItems
      }
    ] : []
  );
  
  const selected = useMemo(() => [searchObject], [searchObject]);

  return <OptionList onChange={onChange} sections={relationSections} options={listItems} selected={selected} />;
}

function ExampleDataItem({ children }) {
  return (
    <Tooltip content={children}>
      <div
        style={{
          position: "relative",
          padding: "0 0.4rem",
          borderRadius: "3px",
          backgroundColor: "var(--p-surface-subdued)",
          display: "block",
          fontSize: "1.15em",
          boxShadow: "inset 0 0 0 1px var(--p-border-subdued)",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          minWidth: "0",
          maxWidth: "16rem",
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
}

function FieldListItem({queryOnly, dataRow, active, filterActive, toggleActiveField, toggleActiveFilterField, searchView, topLevelItem }) {
  const {
    id,
    type: fieldType,
    name: fieldName,
    displayPath,
    collapsedNames,
    tooltip,
    querySource,
    cross,
    shopifyData,
  } = dataRow;
  // const { show } = useFeatureContext({ featureName: "computed-fields", featureValue: true });
  const path = searchView ? displayPath : collapsedNames;

  const toggleThisActiveField = useCallback(() => toggleActiveField(dataRow.id, !active), [active, dataRow.id, toggleActiveField]);
  const toggleThisActiveFilterField = useCallback(() => {
    if (filterActive) {
      toggleActiveFilterField(dataRow.id, false);
    } else {
      toggleActiveFilterField(dataRow.id, true);
    }
  }, [dataRow.id, filterActive, toggleActiveFilterField]);

  const recommended = querySource === "both" || querySource === "shopify";
  const upgradeRequired = /metafields|transactions|risk/.test(id);

  const fieldListItemLabel = (
    <Stack spacing="tight">
      <Stack.Item>
        <TextStyle variation="subdued">{path.map((part) => `${part} > `)}</TextStyle>
        {fieldName} {cross && cross[0] && `(from ${capitalizeString(cross[0])})`}
      </Stack.Item>
      {cross && cross.length > 0 ? (
        <Stack.Item>
          <Tooltip
            preferredPosition="above"
            content={
              <>
                <TextStyle>Computed Field from </TextStyle>
                <TextStyle variation="strong">{capitalizeString(cross[0])}s</TextStyle>
                <TextStyle> - this may slow down report generation time.</TextStyle>
              </>
            }
          >
            <Icon source={ClockMinor} color="subdued" />
          </Tooltip>
        </Stack.Item>
      ) : (
        <></>
      )}
      {fieldType === "computed" ? (
        <Stack.Item>
          <UpgradeBadge asPopover />
        </Stack.Item>
      ) : (
        <></>
      )}
      {fieldType === "computed" && cross.length === 0 ? (
        <Stack.Item>
          <Tooltip preferredPosition="above" content="This is a Modd computed field.">
            <span className="Polaris-Icon">
              <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable={false} aria-hidden fill="#ddd">
                <path d="M8.03774 0.858582C8.10805 0.365936 8.52993 0 9.02749 0H11.2931C11.7907 0 12.2125 0.365936 12.2829 0.858582L12.6542 3.45728C13.3566 3.72517 14.0048 4.10292 14.5778 4.56953L17.0155 3.591C17.4774 3.40564 18.0053 3.58806 18.254 4.01904L19.3869 5.98094C19.6356 6.41193 19.5297 6.96028 19.1383 7.26755L17.0727 8.88898C17.1115 9.13266 17.1378 9.38052 17.1508 9.6319C17.1571 9.7538 17.1603 9.87653 17.1603 10C17.1603 10.3781 17.1303 10.7492 17.0727 11.111L19.1383 12.7324C19.5297 13.0397 19.6356 13.5881 19.3869 14.019L18.254 15.9809C18.0053 16.4119 17.4774 16.5943 17.0155 16.409L14.5778 15.4305C14.0048 15.8971 13.3566 16.2748 12.6542 16.5427L12.2829 19.1414C12.2125 19.6341 11.7907 20 11.2931 20H9.02749C8.52993 20 8.10805 19.6341 8.03774 19.1414L7.6664 16.5427C6.96401 16.2748 6.31582 15.897 5.74282 15.4304L3.30483 16.409C2.84292 16.5943 2.31509 16.4119 2.06631 15.9809L0.933738 14.019C0.684714 13.5881 0.790671 13.0397 1.18227 12.7324L3.24795 11.1109C3.19033 10.7491 3.1603 10.3781 3.1603 10C3.1603 9.62193 3.19033 9.25085 3.24795 8.88904L1.18227 7.26755C0.790671 6.96028 0.684714 6.41193 0.933738 5.98094L2.06631 4.01904C2.31509 3.58806 2.84292 3.40564 3.30483 3.591L5.74282 4.5696C6.31582 4.10295 6.96401 3.72517 7.6664 3.45728L8.03774 0.858582Z" />
              </svg>
            </span>
          </Tooltip>
        </Stack.Item>
      ) : (
        <></>
      )}
      {
      <Stack.Item>
        <Tooltip preferredPosition="above" content={tooltip}>
          <Icon color="subdued" source={InfoMinor}/>
        </Tooltip>
      </Stack.Item>
      }
    </Stack>
  );
  return (
    <FeatureGate
      featureName="computed-fields"
      featureValue
      render={(featureContext) => {
        const { show } = featureContext;
        const displayFieldButton = displayPath[0].toLowerCase() === topLevelItem && !queryOnly;
        const fieldButton = displayFieldButton ? (
          <Stack.Item>
            <Button
              size="slim"
              icon={active ? DeleteMinor : AddMajor}
              pressed={active}
              onClick={toggleThisActiveField}
              accessibilityLabel={active ? "Remove Column" : "Add Column"}
              disabled={(fieldType === "computed" || upgradeRequired) && show}
            />
          </Stack.Item>
        ) : (
          <span style={{
            width:"2.8rem",
            display:"inline-block"
          }}/>
        );

        return (
          <div className="field-list__item">
            <Stack alignment="center" distribution="leading" spacing="tight">
              {fieldButton}
              <Stack.Item>
                <Button
                  size="slim"
                  icon={FilterMajor}
                  pressed={filterActive}
                  disabled={(fieldType === "computed" || upgradeRequired) && show}
                  onClick={toggleThisActiveFilterField}
                  accessibilityLabel={recommended ? "Recommended Filter" : "Filter"}
                />
              </Stack.Item>
              <Stack.Item>{fieldListItemLabel}</Stack.Item>

              {shopifyData?.length >= 1 ? (
                <Stack.Item>
                  <ExampleDataItem>{shopifyData[0]}</ExampleDataItem>
                </Stack.Item>
              ) : null}
              {shopifyData?.length >= 2 ? (
                <Stack.Item>
                  <ExampleDataItem>{shopifyData[1]}</ExampleDataItem>
                </Stack.Item>
              ) : null}
              {shopifyData?.length >= 3 ? (
                <Stack.Item>
                  <ExampleDataItem>{shopifyData[2]}</ExampleDataItem>
                </Stack.Item>
              ) : null}
              {shopifyData?.length === 4 ? <Stack.Item> and 1 more value</Stack.Item> : null}
              {shopifyData?.length > 4 ? <Stack.Item> and {shopifyData.length - 3} more values</Stack.Item> : null}
            </Stack>
          </div>
        );
      }}
    />
  );
}

function FieldList({ topLevelItem, activeFields, activeFilterFields, toggleActiveField, toggleActiveFilterField, dataRows, queryRows, searchView }) {
  return (
    <Stack.Item>
      <Stack vertical spacing="tight">
        {dataRows.sort(sortFields).map((dataRow) => (
          <FieldListItem
            topLevelItem={topLevelItem}
            queryOnly={0}
            key={dataRow.id}
            dataRow={dataRow}
            active={activeFields.has(dataRow.id)}
            filterActive={activeFilterFields.has(dataRow.id)}
            toggleActiveField={toggleActiveField}
            toggleActiveFilterField={toggleActiveFilterField}
            searchView={searchView}
          />
        ))}
         {queryRows.sort(sortFields).map((dataRow) => (
          <FieldListItem
            topLevelItem={topLevelItem}
            queryOnly={1}
            key={dataRow.id}
            dataRow={dataRow}
            active={activeFields.has(dataRow.id)}
            filterActive={activeFilterFields.has(dataRow.id)}
            toggleActiveField={toggleActiveField}
            toggleActiveFilterField={toggleActiveFilterField}
            searchView={searchView}
          />
        ))}
      </Stack>
    </Stack.Item>
  );
}

function CustomFieldBlurb({ searchView, activeRelations, searchObject, topLevelItem }) {
  const [, dispatch] = useReportTemplate();

  const addReportFilter = useCallback((value) => dispatch({ type: "addReportFilter", value }), [dispatch]);

  const updateReportFields = useCallback((id, value) => dispatch({ type: "updateReportFields", value: { id, value } }), [dispatch]);

  const addCustomField = (props) => {
    updateReportFields(null, {
      id: `${props}-static_field_${+new Date()}`,
      name: `Custom ${props[0].toUpperCase() + props.substr(1)} Field`,
      text: "",
    });
  };

  const addLiquidFilter = (props) => {
    const id = `${props}-liquid`;
    addReportFilter({ id, conditions: [{ id: +new Date(), operator: LIQUID_FILTER_OPERATORS[0] }] });
  };

  const customField = searchObject && searchObject.split('-')[0] === topLevelItem ? (
    <Button size="slim" onClick={() => addCustomField(searchObject)} icon={AddCodeMajor}>
    Add Liquid Field
  </Button>
  ) : (<></>);

  return (
    !searchView && (
      <Stack vertical>
        <Subheading>{activeRelations.map((field) => field.name).join(" > ")}</Subheading>
        {searchObject && (
          <Stack>
            {customField}
            <Button size="slim" onClick={() => addLiquidFilter(searchObject)} icon={AddCodeMajor}>
              Add Liquid Filter
            </Button>
          </Stack>
        )}
      </Stack>
    )
  );
}

export function FieldListFull({
  topLevelItem,
  additionalRelationItems,
  activeFields,
  activeFilterFields,
  toggleActiveField,
  toggleActiveFilterField,
  objectFields,
  queryFields,
  reducedFieldList,
}) {
  const [{ reportFormat, mainItem }, , { shopifyData }] = useReportTemplate();
  const [{ queryValue }] = useFieldListContext();
  const [searchObject, setSearchObject] = useState(topLevelItem);

  const searchObjectField = useMemo(() => objectFields.find((field) => field.id === searchObject), [objectFields, searchObject]);

  const rawDataRows = useMemo(
    () =>
      objectFields
        .filter(
          ({ mainObject }) =>
            topLevelItem === mainObject ||
            (additionalRelationItems?.includes?.(mainObject))
        )
        .map((item) => {
          const path = item.id.split("-").slice(1);
          const fieldValue = traverseObject(shopifyData, path, reportFormat.separator);
          return { ...item, shopifyData: fieldValue };
        }),
    [objectFields, topLevelItem, additionalRelationItems, shopifyData, reportFormat]
  );

  const rawQuerries = useMemo(
    () =>
    queryFields
        .filter(
          ({ mainObject }) =>
            topLevelItem === mainObject ||
            (additionalRelationItems?.includes?.(mainObject))
        )
        .map((item) => {
          const path = item.id.split("-").slice(1);
          const fieldValue = traverseObject(shopifyData, path, reportFormat.separator);
          return { ...item, shopifyData: fieldValue };
        }),
    [queryFields, topLevelItem, additionalRelationItems, shopifyData, reportFormat]
  );        
  const fieldDataRows = useMemo(() => rawDataRows.filter(({ relation }) => !relation), [rawDataRows]);
  const relationDataRows = useMemo(() => rawDataRows.filter(({ relation }) => relation), [rawDataRows]);

  const fieldQueryRows = useMemo(() => rawQuerries.filter(({ relation }) => !relation), [rawQuerries]);

  useEffect(() => {
    if (!relationDataRows.some(({ id }) => id === searchObject) && searchObject !== false) {
      if (relationDataRows.length > 1) {
        const topLevelItemDataRow = relationDataRows.find(({ id, type }) => type === "main" && id === mainItem);
        setSearchObject(topLevelItemDataRow && topLevelItemDataRow.id);
      } else {
        setSearchObject(relationDataRows[0] && relationDataRows[0].id);
      }
    }
  }, [mainItem, relationDataRows, searchObject]);

  const searchView = queryValue && queryValue.trim().length > 1;

  const dataRows = useMemo(
    () =>
      fieldDataRows.filter((field) =>
        searchView
          ? fieldContains(field, queryValue)
          : searchObject === field.displayParent ||
          searchObject === false ||
          (searchObject === REDUCED_FIELD_LIST_SEARCH_OBJECT_ID.toString() && reducedFieldList.includes(field.id))
      ),
    [fieldDataRows, queryValue, reducedFieldList, searchObject, searchView]
  );
  const queryRows = useMemo(
    () =>
    fieldQueryRows.filter((field) =>
        searchView
          ? fieldContains(field, queryValue)
          : searchObject === field.displayParent ||
          searchObject === false ||
          (searchObject === REDUCED_FIELD_LIST_SEARCH_OBJECT_ID.toString() && reducedFieldList.includes(field.id))
      ),
    [fieldQueryRows, queryValue, reducedFieldList, searchObject, searchView]
  );
  
  const ids = new Set(dataRows.map(d => d.id));
  const filteredQueryRows = [...queryRows.filter(d => !ids.has(d.id))];

  const activeRelations = useMemo(() => {
    const fields = searchObjectField ? [searchObjectField] : [];

    while (fields.length && fields[0].displayParent) {
      const nextField = objectFields.find((field) => field.id === fields[0].displayParent);
      if (nextField) {
        fields.unshift(nextField);
      } else {
        break;
      }
    }
    return fields;
  }, [objectFields, searchObjectField]);

  const [fieldCategoryBrowserOpen, setFieldCategoryBrowserOpen] = useState(false);
  const toggleFieldCategoryBrowserOpen = useCallback(
    () => setFieldCategoryBrowserOpen(!fieldCategoryBrowserOpen),
    [fieldCategoryBrowserOpen]
  );

  const [hasEnoughSpace, setHasEnoughSpace] = useState(false);

  const [observer] = useState(
    new ResizeObserver(([entry] = []) => {
      const width = entry?.borderBoxSize?.[0]?.inlineSize;
      setHasEnoughSpace(width > MIN_WIDTH_FOR_FIELD_CATEGORY_BROWSER);
    })
  );

  useEffect(
    () => () => {
      observer.disconnect();
    },
    [observer]
  );

  const updateRef = useCallback(
    (el) => {
      if (el) {
        observer.observe(el);
      } else {
        observer.disconnect();
      }
    },
    [observer]
  );

  const objectBrowser = (
    <ObjectBrowser
      key={topLevelItem}
      activeRelations={activeRelations}
      topLevelItem={topLevelItem}
      objectFields={objectFields}
      searchObject={searchObject}
      setSearchObject={setSearchObject}
      reducedFieldList={reducedFieldList}
      additionalRelationItems={additionalRelationItems}
    />
  );

  return (
    <Stack vertical>
      {hasEnoughSpace ? null : (
        <Popover
          active={fieldCategoryBrowserOpen}
          onClose={toggleFieldCategoryBrowserOpen}
          activator={
            <Button
              disclosure
              onClick={toggleFieldCategoryBrowserOpen}
              label={activeRelations.map((field) => field.name).join(" > ")}
              icon={MobileHamburgerMajor}
            >
              Select fields from a different Object
            </Button>
          }
        >
          {objectBrowser}
        </Popover>
      )}
      <div style={{ display: "flex", flexFlow: "row", marginLeft: "-1rem" }} ref={updateRef}>
        {!searchView && hasEnoughSpace && (
          <div style={{ flex: "0 1 25rem", paddingLeft: "1rem" }}>
            <Stack vertical spacing="tight">
              <Subheading>Object Categories</Subheading>
              <div style={{ marginLeft: "-2rem" }}>{objectBrowser}</div>
            </Stack>
          </div>
        )}

        <div style={{ flex: "1 0 30rem", paddingLeft: "1rem" }}>
          <Stack vertical>
            <CustomFieldBlurb
              activeRelations={activeRelations}
              searchObject={searchObject}
              searchView={searchView}
              setSearchObject={setSearchObject}
              topLevelItem={topLevelItem}
            />
            <Stack.Item>
              <FieldList
                topLevelItem={topLevelItem}
                activeFields={activeFields}
                activeFilterFields={activeFilterFields}
                dataRows={dataRows}
                queryRows={filteredQueryRows}
                searchView={searchView}
                toggleActiveField={toggleActiveField}
                toggleActiveFilterField={toggleActiveFilterField}
              />
            </Stack.Item>
          </Stack>
        </div>
      </div>
    </Stack>
  );
}
