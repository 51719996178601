import React, { useEffect, useState } from "react";

import {
  Select,
  Checkbox,
  Stack,
  Button,
  Tag,
  TextField,
  TextStyle,
  Modal,
  Collapsible,
  Banner,
  List,
  TextContainer,
  Link,
  Label,
} from "@shopify/polaris";

import { UpgradeBadge, FeatureGate, useProfile } from "admin-frontend";
import { useReportTemplate } from "../../TemplateContext";
import { useXporterApi } from "../../ApiProvider";
import { tomorrow } from "../../dateUtil";
import DateField from "../DateField";

const REPORT_GENERATION_INTERVALS = {
  minutes: ["15", "30", "45"],
  hours: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ],
  days: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
  weeks: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
  ],
  months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
  years: ["1", "2"],
};

const REPORT_TRANSFER_SELECT = [
  { label: "FTP", value: "ftp" },
  { label: "SFTP", value: "sftp" },
  { label: "SCP", value: "scp" },
  { label: "FTPS", value: "ftps" },
  { label: "Google Spreadsheet", value: "google-spreadsheet", hidden: true },
];

const TRANSFER_PORT = {
  ftp: 21,
  sftp: 22,
  scp: 22,
  ftps: 990,
};

const DEFAULT_STATE = {
  email: null,
  ftp: null,

  automatic_transfer_empty: false,
  automatic_saving: true,
  duration_number: "1",
  duration_type: "days",
};

const inflatedDefaultState = (profile) => ({
  ...DEFAULT_STATE,
  report_next_generation: tomorrow(profile),
});

function EmailSection(value, update) {
  const showEmail = value;
  const [emailRecipientsInput, setEmailRecipientsInput] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");

  const {
    emails: emailRecipients,
    subject: emailSubject,
    filename: emailFilename,
    attachment: emailAttachment,
    body: emailBody,
  } = value || {};

  const removeRecipient = (removedEmail) => () => {
    update({ ...value, emails: emailRecipients.filter((email) => email !== removedEmail) });
  };

  const emailTags =
    typeof emailRecipients === "object" && emailRecipients.length > 0 ? (
      emailRecipients.map((r) => (
        <Tag key={r} onRemove={removeRecipient(r)}>
          {r}
        </Tag>
      ))
    ) : (
      <></>
    );

  const validateEmail = () => {
    if (emailRecipientsInput === "") {
      setEmailRecipientsInput("");
      setInvalidEmail("");
      return false;
    }
    if (
      emailRecipientsInput.match(
        // eslint-disable-next-line no-control-regex
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      )
    ) {
      setEmailRecipientsInput("");
      setInvalidEmail("");
      update({ ...value, emails: [...emailRecipients, emailRecipientsInput] });
      return true;
    }
    setInvalidEmail("Invalid email");
    return false;
  };

  const handleKeyPress = (event) => {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      event.preventDefault();
      validateEmail();
    }
  };

  return (
    <Collapsible open={showEmail} transition={{ duration: "500ms", timingFunction: "ease-in-out" }}>
      <TextContainer>
        <p>Email the recipients below once this report has finished it&apos;s scheduled generation.</p>
        <br />
      </TextContainer>
      <Stack vertical>
        <div onKeyDown={handleKeyPress} role="button" tabIndex={0}>
          <TextField
            id="emailRecipientsInput"
            label="Recipients"
            type="email"
            inputMode="email"
            value={emailRecipientsInput}
            onChange={setEmailRecipientsInput}
            connectedRight={
              <Button primary="true" onClick={validateEmail}>
                Add
              </Button>
            }
            clearButton
            onClearButtonClick={() => {
              setEmailRecipientsInput("");
              setInvalidEmail("");
            }}
            error={invalidEmail}
          />
        </div>
        <Stack spacing="loose">{emailTags}</Stack>
        <TextField
          label="Subject"
          value={emailSubject}
          onChange={(data) => {
            update({ ...value, subject: data });
          }}
        />
        <TextField
          label="Email Body"
          multiline={3}
          value={emailBody}
          onChange={(data) => {
            update({ ...value, body: data });
          }}
        />
        <Link
          external
          href="https://moddapps.zendesk.com/hc/en-us/articles/4415582047891"
          url="https://moddapps.zendesk.com/hc/en-us/articles/4415582047891"
        >
          click here to see available liquid fields
        </Link>
        <TextField
          label="Attachment"
          value={emailFilename}
          onChange={(data) => {
            update({ ...value, filename: data });
          }}
          placeholder="Name"
          disabled={emailAttachment === "none"}
          error={emailAttachment !== "none" && emailFilename?.trim()?.length === 0 ? "Can't be blank" : null}
          connectedLeft={
            <Select
              options={["none", "zip", "file"]}
              onChange={(data) => {
                update({ ...value, attachment: data });
              }}
              value={emailAttachment}
            />
          }
        />
      </Stack>
      {showEmail && <hr />}
    </Collapsible>
  );
}

function FtpSection(value, update) {
  const {
    protocol: fileTransferType,
    port: fileTransferPort,
    hostname: fileTransferHost,
    username: fileTransferUser,
    password: fileTransferPassword,
    path: fileTransferPath,
    binary: fileTransferFTPBinary,
    passive: fileTransferFTPMode,
    key: fileTransferKey,
  } = value || {
    protocol: "",
    port: 0,
    hostname: "",
    username: "",
    password: "",
    path: "",
    binary: false,
    passive: false,
    key: "",
    file_type: "",
  };

  const [testResults, setTestResults] = useState(null);

  const [loadingResults, setLoadingResults] = useState(false);
  const [currentTemplate] = useReportTemplate();
  const xporterApi = useXporterApi();

  const setFileTransferSCPConnection = (val) => {
    const data =
      !val || val === "false"
        ? {
            password: fileTransferPassword || "",
            key: null,
          }
        : {
            password: null,
            key: fileTransferKey || "",
          };
    update({ ...value, ...data });
  };

  const setFileTransferPassword = (val) => update({ ...value, password: val });
  const fileTransferSCPConnection =
    fileTransferPassword === null || (fileTransferPassword === "" && fileTransferKey !== null && fileTransferKey !== "");

  const testFTPSettings = () => {
    setLoadingResults(true);
    setTestResults(null);
    xporterApi
      .testTemplateFtp(currentTemplate.reportGeneration.ftp, currentTemplate.reportFormat)
      .then(() => setTestResults({ type: "positive", msg: "successfully transfered test file." }))
      .catch(
        ({
          error = "Unable to connect to FTP. Please check server information, and ensure our IP is whitelisted (35.203.32.82)",
        }) => setTestResults({ type: "negative", msg: error })
      )
      .finally(() => setLoadingResults(false));
  };

  const [fileTransferHostError, setFileTransferHostError] = useState(null);
  useEffect(() => {
    const VALIDHOSTNAMEREGEX =
      /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;

    setFileTransferHostError(null);
    if (typeof fileTransferHost === "string" && fileTransferHost !== "") {
      if (fileTransferHost.trim() === 0) setFileTransferHostError("can't be empty");
      if (!fileTransferHost.match(VALIDHOSTNAMEREGEX)) setFileTransferHostError("invalid hostname");
      if (fileTransferHost.match(":")) setFileTransferHostError("invalid character :");
      if (fileTransferHost.match(/:(\d+)/)) setFileTransferHostError("please specify port below");
    }
  }, [fileTransferHost]);

  return (
    <Collapsible open={value} transition={{ duration: "500ms", timingFunction: "ease-in-out" }}>
      <TextContainer>
        <p>Transfer your report once it completes it&apos;s scheduled generation.</p>
        <br />
      </TextContainer>
      <Stack vertical>
        <Select
          label="Protocol"
          value={fileTransferType}
          options={REPORT_TRANSFER_SELECT}
          onChange={(data) => {
            let passkey = {};
            let port = fileTransferPort;

            if (TRANSFER_PORT[fileTransferType] === fileTransferPort) {
              port = TRANSFER_PORT[data];
            }

            if (data === "ftp") {
              passkey = {
                password: fileTransferPassword ?? "",
                key: null,
              };
            }
            update({ ...value, protocol: data, port, ...passkey });
          }}
        />
        <TextField
          label="Hostname"
          value={fileTransferHost}
          onChange={(data) => {
            update({ ...value, hostname: data });
          }}
          placeholder="ftp.mydomain.com"
          error={fileTransferHostError}
        />
        <TextField
          label="Port"
          type="number"
          min={1}
          value={fileTransferPort.toString()}
          onChange={(data) => {
            update({ ...value, port: Number(data) });
          }}
          placeholder={21}
          error={fileTransferPort === 0 ? "can't be zero" : ""}
        />
        <TextField
          label="Path"
          value={fileTransferPath}
          onChange={(data) => {
            update({ ...value, path: data });
          }}
          placeholder="./{{ report.category }}-{{ report.create_date | date: '%F' }}.{{ report.extension }}"
          error={fileTransferPath.trim() === 0 ? "can't be empty" : ""}
          helpText={
            <Stack>
              <TextStyle variation="subdued">
                {" "}
                {
                  'Please enter the full path, including filename. You can use liquid variables here, for example /folder/ReportName{{report.create_date | date:"%Y%m%d"}}.report.extension'
                }
              </TextStyle>
              <TextStyle variation="subdued">Spaces in your path will cause delivery failures</TextStyle>
              <Link
                external
                href="https://moddapps.zendesk.com/hc/en-us/articles/4415582047891"
                url="https://moddapps.zendesk.com/hc/en-us/articles/4415582047891"
              >
                Click here to see available liquid fields
              </Link>
            </Stack>
          }
        />

        <TextField
          label="Username"
          value={fileTransferUser}
          onChange={(data) => {
            update({ ...value, username: data });
          }}
          placeholder="ftpUser"
          error={fileTransferUser.trim() === 0 ? "can't be empty" : ""}
        />

        {fileTransferType.match(/^s/) && (
          <Select
            label="Authentication"
            options={[
              { label: "Password", value: false },
              { label: "Private Key", value: true },
            ]}
            value={fileTransferSCPConnection}
            onChange={setFileTransferSCPConnection}
          />
        )}

        {!fileTransferSCPConnection && (
          <TextField
            label="Password"
            type="password"
            value={fileTransferPassword}
            onChange={setFileTransferPassword}
            placeholder="PASSWORD"
          />
        )}

        {fileTransferSCPConnection && (
          <TextField
            label="SSH Key"
            multiline={6}
            value={fileTransferKey}
            onChange={(data) => {
              update({ ...value, key: data });
            }}
            placeholder="-----BEGIN RSA PRIVATE KEY-----
            ...."
            error={fileTransferKey.trim() === 0 ? "can't be empty" : ""}
          />
        )}

        {fileTransferType.match(/^f/) && (
          <>
            <Select
              label="Binary Mode"
              options={[
                { label: "yes", value: "1" },
                { label: "ascii mode", value: "0" },
              ]}
              value={fileTransferFTPBinary}
              onChange={(data) => {
                update({ ...value, binary: data });
              }}
            />
            <Select
              label="Transfer Mode"
              options={[
                { label: "Passive (recommended)", value: "1" },
                { label: "Active", value: "0" },
              ]}
              value={fileTransferFTPMode}
              onChange={(data) => {
                update({ ...value, passive: data });
              }}
            />
          </>
        )}
        <Stack alignment="center">
          <Stack.Item>
            <Button onClick={testFTPSettings} loading={loadingResults}>
              Test
            </Button>
          </Stack.Item>
          <Stack.Item>
            {testResults && (
              <TextStyle variation={testResults.type}>
                <p>{testResults.msg}</p>
              </TextStyle>
            )}
          </Stack.Item>
        </Stack>
      </Stack>
      {value && <hr />}
    </Collapsible>
  );
}

function AutoGenModal({ open, toggleOpen }) {
  const [{ reportGeneration: value }, dispatch] = useReportTemplate();
  const [profile] = useProfile();
  // data holders
  const { shop, user } = useProfile()[0];
  const userEmail = user?.email;

  const {
    ftp: Ftp,
    email: Emails,
    automatic_transfer_empty: transferEmpty,
    automatic_saving: saveServer,
    duration_number: durationNumber,
    duration_type: durationType,
  } = value || {
    email: null,
    ftp: null,
    automatic_transfer_empty: false,
    automatic_saving: false,
    duration_number: "1",
    duration_type: "days",
  };

  const nextGeneration = value?.report_next_generation;

  const [errors, setErrors] = useState([]);

  const validateSettings = (newValue) => {
    if (newValue == null) {
      setErrors([]);
      return true;
    }

    const e = [];

    if (newValue?.email) {
      if (newValue.email.emails.length === 0) e.push("No Email Recipients.");

      if (newValue.email.attachment !== "none" && newValue.email.filename.trim().length === 0)
        e.push("Email attachment name can't be blank");
    }

    if (newValue?.ftp) {
      if (newValue.ftp.hostname.trim().length === 0) e.push(`${newValue.ftp.protocol} hostname can't be blank `);

      if (newValue.ftp.port === null || newValue.ftp.port === "") e.push(`${newValue.ftp.protocol} port can't be zero `);

      if (newValue.ftp.path.trim().length === 0) e.push(`${newValue.ftp.protocol} path can't be blank `);

      if (newValue.ftp.username.trim().length === 0) e.push(`${newValue.ftp.protocol} username can't be blank `);
    }

    setErrors(e);
    return e.length === 0;
  };

  const update = (data) => {
    let newValue = { ...value, ...data };
    validateSettings(newValue);
    if (newValue.email === null && newValue.ftp === null && newValue.automatic_saving === false) {
      newValue = null;
    }
    dispatch({ type: "merge", value: { reportGeneration: newValue } });
  };

  // modal things

  const modalDiscard = () => {
    toggleOpen();
    dispatch({ type: "popState" });
    setErrors([]);
  };

  const modalSave = () => {
    if (validateSettings(value)) {
      toggleOpen();
      setErrors([]);
    }
  };

  return (
    <Modal
      large
      open={open}
      //       title="Automatic Generation Settings"
      title={
        <>
          <span style={{ display: "inline-flex" }}>Automatic Generation Settings</span>
          <span style={{ fontSize: "0.5em", float: "right" }}>
            <Link
              external
              href="https://moddapps.zendesk.com/hc/en-us/articles/4415582047891"
              url="https://moddapps.zendesk.com/hc/en-us/articles/4415582047891"
            >
              Learn More
            </Link>
          </span>
        </>
      }
      primaryAction={{ content: "Close", onAction: modalSave }}
      onClose={modalDiscard}
    >
      <Modal.Section>
        {/* Generation */}
        {errors.length > 0 && (
          <Banner status="warning" title={`To automatically generate, ${errors.length} changes need to be made`}>
            <List type="bullet">
              {errors.map((errorString) => (
                <List.Item>{errorString}</List.Item>
              ))}
            </List>
          </Banner>
        )}
        <FeatureGate
          featureName="automatic-generation"
          featureValue={1}
          render={({ show }) => (
            <Checkbox
              checked={value}
              disabled={show}
              label={
                <Stack>
                  <Label>Set your template to generate on a set schedule.</Label>
                  <UpgradeBadge />
                </Stack>
              }
              onChange={(newChecked) => {
                if (newChecked) {
                  dispatch({ type: "merge", value: { reportGeneration: inflatedDefaultState(profile) } });
                } else {
                  dispatch({ type: "merge", value: { reportGeneration: null } });
                }
              }}
            />
          )}
        />
        <Stack vertical>
          <Stack.Item>
            <DateField
              label="Start generation on"
              value={nextGeneration}
              onChange={(data) => {
                update({ report_next_generation: data });
              }}
              suffix={shop.timezone.split(" ")[0]}
              disabled={!value}
            />
          </Stack.Item>
          <Stack alignment="trailing">
            <Select
              label="Run every"
              options={REPORT_GENERATION_INTERVALS[durationType]}
              onChange={(data) => {
                update({ duration_number: Number(data) });
              }}
              value={String(durationNumber || "1")}
              disabled={!value}
            />
            <FeatureGate
              featureName="export-hourly"
              featureValue={1}
              render={({ show, planName }) => (
                <Select
                  options={Object.keys(REPORT_GENERATION_INTERVALS).flatMap((x) => {
                    const option = { label: x, value: x };
                    if (x === "minutes" || x === "hours") {
                      if (show) {
                        option.label = `${x} (${planName})`;
                        option.disabled = true;
                      }
                    }
                    return option;
                  })}
                  onChange={(data) => {
                    if (data === "minutes" && durationType !== "minutes") {
                      update({ duration_type: data, duration_number: "30" });
                    } else {
                      update({ duration_type: data, duration_number: "1" });
                    }
                  }}
                  value={durationType}
                  disabled={!value}
                />
              )}
            />
            <p>
              Note, &quot;Within Date Range&quot; , &quot;After Start Date&quot; and &quot;Before End Date&quot; filters will
              automatically use this interval.
              <br /> For example, each report will run for the last {`${durationNumber} ${durationType}`}
            </p>
          </Stack>
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <div style={{ maxWidth: "99%" }}>
          <Stack vertical>
            <Checkbox
              label="Transfer/Save Empty Reports"
              checked={Boolean(Number(transferEmpty))} // keep this here so we don't have to migrate the db. In 12 months you can maybe refactor this out
              onChange={(data) => {
                update({ automatic_transfer_empty: Number(data) });
              }}
              disabled={!value}
            />
            <Checkbox
              label="Save report in app"
              checked={Boolean(Number(saveServer))} // keep this here so we don't have to migrate the db. In 12 months you can maybe refactor this out
              onChange={(data) => {
                update({ automatic_saving: Number(data) });
              }}
              disabled={!value}
            />
            <Checkbox
              label="Email report"
              checked={Emails}
              onChange={(data) => {
                if (data) {
                  update({
                    email: {
                      emails: userEmail ? [userEmail] : [],
                      subject: "{{ report.category }} Report ",
                      filename: "{{ report.category }}-{{ report.create_date | date: '%F' }}.{{ report.extension }}",
                      body: "see attached report",
                      attachment: "file",
                    },
                  });
                } else {
                  update({ email: null });
                }
              }}
              disabled={!value}
            />

            {EmailSection(Emails, (data) => {
              update({ ...value, email: data });
            })}

            <FeatureGate
              featureName="ftp"
              featureValue
              render={(state) => (
                <Stack alignment="center">
                  <Checkbox
                    label={<TextStyle variation={state?.show ? "subdued" : null}>FTP report</TextStyle>}
                    disabled={state?.show || !value}
                    checked={Ftp}
                    onChange={(data) => {
                      if (data) {
                        const ftp = {
                          protocol: "ftp",
                          port: 21,
                          hostname: "example.com",
                          username: "admin",
                          password: "password",
                          path: "./{{ report.category }}-{{ report.create_date | date: '%F' }}.{{ report.extension }}",
                          binary: "1",
                          passive: "1",
                          key: null,
                          file_type: "file",
                        };
                        update({ ftp });
                      } else {
                        update({ ...value, ftp: null });
                      }
                    }}
                  />
                  <UpgradeBadge asPopover />
                </Stack>
              )}
            />
            {FtpSection(Ftp, (data) => {
              update({ ...value, ftp: data });
            })}
          </Stack>
        </div>
      </Modal.Section>
    </Modal>
  );
}

export default AutoGenModal;
