/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Link, Stack, Button, EmptyState, Filters, Icon, Select, TextStyle, Card, Tooltip } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import { useLocation, useHistory } from "react-router-dom";
import { useProfile, useCreateModal } from "admin-frontend";
import { DateTime } from "luxon";

import { useXporterApi, useXporterFileListing, useXporterFileListingCount } from "../ApiProvider";
import FileListingTable, { normalizeFileListingResponse, useDeleteFile } from "./FileListingTable";

const MAX_TEMPLATE_NAME_LENGTH = 48;

const SORT_OPTIONS = [
  { label: "Created At", value: "created_at" },
  { label: "Start Time", value: "start" },
  { label: "End Time", value: "end" },
];

const filter = (filters, allReports) => {
  let reports = allReports.filter(Boolean);

  if (filters.template) {
    reports = reports.filter((report) => filters.template === report.TEMPLATE_ID);
  }

  if (filters.query) {
    reports = reports.filter(({ NAME }) => {
      const words = NAME.split(/\W+/).filter(Boolean);
      const found = words.find((w) => w.toLowerCase().includes(filters.query.toLowerCase()));

      return Boolean(found);
    });
  }

  if (filters.sort) {
    switch (filters.sort) {
      case "start":
        reports.sort((a, b) => DateTime.fromISO(b.START) - DateTime.fromISO(a.START));
        break;
      case "end":
        reports.sort((a, b) => DateTime.fromISO(b.END) - DateTime.fromISO(a.END));
        break;
      case "created_at":
        reports.sort((a, b) => DateTime.fromISO(b.DATE) - DateTime.fromISO(a.DATE));
        break;
      default:
        break;
    }
  }

  return reports;
};

function ReportEmptyState({ totalCount, reports }) {
  if (totalCount === null) {
    return (
      <EmptyState>
        <p>Something went wrong 💢</p>
      </EmptyState>
    );
  }

  if (totalCount > reports.length) {
    return (
      <EmptyState>
        <p>
          No results found in {reports.length} most recent reports (of {totalCount} total)
        </p>
        <br />
        <p>To search more reports click load more</p>
      </EmptyState>
    );
  }
  return <EmptyState>{totalCount !== null && <p>Your generated reports will appear here.</p>}</EmptyState>;
}

function ReportTable({ limit = 100 }) {
  const xporterApi = useXporterApi();
  const history = useHistory();
  const location = useLocation();
  const createModal = useCreateModal();
  const deleteFile = useDeleteFile();

  const [selected, setSelected] = useState(new Set());

  const [viewSize, setViewSize] = useState(limit);
  const allReports = useXporterFileListing(0, viewSize);
  const totalCount = useXporterFileListingCount();
  const loadedFiles = useMemo(() => allReports.every(Boolean), [allReports]);

  const queryparams = new URLSearchParams(location.search);
  const queryTemplateId = Number(queryparams.get("template_id"));
  const queryTemplateName = queryparams.get("template_name");

  const [filterSort, setFilterSort] = useState(SORT_OPTIONS[0].value);
  const [filterTemplate, setFilterTemplate] = useState(queryTemplateId);
  const [filterQuery, setFilterQuery] = useState(queryTemplateName);

  const reports = useMemo(
    () =>
      filter(
        {
          sort: filterSort,
          template: filterTemplate,
          query: filterQuery,
        },
        allReports
      ),
    [allReports, filterQuery, filterSort, filterTemplate]
  );

  const pullMoreReports = useCallback(() => {
    setViewSize(viewSize + limit);
  }, [limit, viewSize]);

  const loadMore = totalCount > viewSize;

  // shop.active_subscription.features.report-retention
  const [profile] = useProfile();
  const reportRetention = profile.shop.active_subscription.features["report-retention"].value || 91;

  const items = useMemo(
    () => normalizeFileListingResponse(reports).map((file) => ({ ...file, delete: () => deleteFile(file) })),
    [deleteFile, reports]
  );

  const handleSelectionChange = useCallback(
    (state, ids) => {
      let newSelected = new Set(selected);
      if (ids !== null) {
        if (state) {
          newSelected.add(ids);
        } else {
          newSelected.delete(ids);
        }
      }
      if (ids === null) {
        if (state && selected.size < items.length) {
          newSelected = new Set(items.map(({ key }) => key));
        } else {
          newSelected = new Set();
        }
      }
      setSelected(newSelected);
    },
    [items, selected]
  );

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    xporterApi.getAllTemplates().then(setTemplates);
  }, [xporterApi]);

  const bulkDelete = useCallback(
    (startLoading) => {
      const selectedItems = items.filter((item) => selected.has(item.key));
      const selectedFileIds = selectedItems.map((selectedFile) => selectedFile.id);

      createModal({
        title: "Delete Reports",
        content: `Are you sure you want to delete ${selectedItems.length} reports? This action cannot be undone.`,
        primaryAction: {
          destructive: true,
          content: "Delete Reports",
          onAction: () => {
            const stopLoading = startLoading();
            xporterApi
              .deleteReports(selectedFileIds)
              .catch((ex) => {
                createModal({ title: "Oops..." });
                console.error(ex);
              })
              .finally(() => stopLoading());
          },
        },
        secondaryActions: [
          {
            content: "Cancel",
            onAction: () => setSelected(new Set()),
          },
        ],
      });
    },
    [createModal, items, selected, xporterApi]
  );
  return (
    <>
      <Card.Section>
        <p>Below are all the reports saved in Xporter.</p>
        <Stack distribution="equalSpacing">
          <Stack.Item>
            <p>
              You can download or delete these reports. Reports are kept for <b>{reportRetention} days</b>.{" "}
              <Link
                external
                href="https://support.moddapps.com/hc/en-us/articles/4407451783827"
                url="https://support.moddapps.com/hc/en-us/articles/4407451783827"
              >
                Learn more
              </Link>
            </p>
          </Stack.Item>
          <Stack.Item>
            <Tooltip content="Please note that filtering only works on the currently displayed reports">
              <Stack wrap={false} spacing="extraTight">
                <Icon source={InfoMinor} />
                <TextStyle>Filter and Sort</TextStyle>
              </Stack>
            </Tooltip>
          </Stack.Item>
        </Stack>
      </Card.Section>
      <Card.Section>
        <Filters
          queryValue={filterQuery}
          filters={[]}
          onQueryChange={setFilterQuery}
          onQueryClear={() => setFilterQuery(null)}
          helpText={
            <>
              {}
              {totalCount > 0 && `\u00a0Showing ${reports.length} of ${allReports.length} loaded (${totalCount} total)`}
              {loadMore && (
                <>
                  <br />
                  <p>
                    &nbsp;Due to large amount of report. Searching and filtering is restricted to only the currently loaded reports.
                    Click on `Load More` at the bottom if you want search on more reports
                  </p>
                </>
              )}
            </>
          }
        >
          <Stack wrap={false}>
            <Select
              labelInline
              label="Templates: "
              options={[
                { label: "All", value: null },
                ...templates.map(({ NAME: templateName, ID: templateId }) => ({
                  label:
                    templateName.length > MAX_TEMPLATE_NAME_LENGTH
                      ? `${templateName.substr(0, MAX_TEMPLATE_NAME_LENGTH)}…`
                      : templateName,
                  value: Number(templateId),
                })),
              ]}
              value={filterTemplate}
              onChange={(value) => {
                const templateId = Number(value) ? Number(value) : null;
                setFilterTemplate(templateId);
                if (templateId) {
                  history.replace(`/files?template_id=${templateId}`, history.location.state);
                } else {
                  history.replace("/files", history.location.state);
                }
              }}
            />

            <Select labelInline label="Sort by: " options={SORT_OPTIONS} value={filterSort} onChange={setFilterSort} />
          </Stack>
        </Filters>
      </Card.Section>
      <Card.Section flush>
        <FileListingTable
          items={items}
          selected={selected}
          handleSelectionChange={handleSelectionChange}
          bulkDelete={bulkDelete}
          emptyState={<ReportEmptyState totalCount={totalCount} reports={reports} />}
        />
      </Card.Section>
      {loadMore && (
        <Card.Section>
          <Stack alignment="center" vertical>
            <Stack.Item>
              <Button primary loading={!loadedFiles} onClick={pullMoreReports}>
                Load More
              </Button>
            </Stack.Item>
          </Stack>
        </Card.Section>
      )}
    </>
  );
}

export default ReportTable;
