import React, { useState, useCallback, useEffect, useContext, useMemo } from "react";
import { Icon, Button, Stack, Modal, TextField, IndexTable, Badge, Tooltip, EmptyState } from "@shopify/polaris";
import { SearchMinor, NoteMajor } from "@shopify/polaris-icons";
import { Context as AppBridgeContext, ResourcePicker } from "@shopify/app-bridge-react";
import { DateTime } from "luxon";
import ReactJson from "react-json-view";

import { useUtilityFetch, useProfile } from "admin-frontend";
import { useReportTemplate } from "../../TemplateContext";

const BASE_ROUTE = "/api/shopify/2024-01";

const fetchExtraData = async (fetch, object, mainItem) => {
  if (!object) return null;

  let newObject = object;
  switch (mainItem) {
    case "order":
      newObject = {
        ...newObject,
        ...(await Promise.all([
          fetch(`${BASE_ROUTE}/${mainItem}s/${newObject.id}/transactions.json`).then((response) => response.json()),
          fetch(`${BASE_ROUTE}/${mainItem}s/${newObject.id}/risks.json`).then((response) => response.json()),
        ]).then(([a, b]) => ({ ...a, ...b }))),
      };
      break;
    default:
      break;
  }

  // make this better senpai
  newObject.metafields = [];
  let pageInfo = null;
  do {
    const params = pageInfo ? `limit=250&page=${pageInfo}` : "limit=250";
    // eslint-disable-next-line no-await-in-loop
    const response = await fetch(`${BASE_ROUTE}/${mainItem}s/${newObject.id}/metafields.json?${params}`);
    pageInfo = response.headers.get("page_info");
    // eslint-disable-next-line no-await-in-loop
    const payload = await response.json();
    newObject.metafields = newObject.metafields.concat(Object.values(payload)[0]);
  } while (pageInfo);

  return newObject;
};

function ProductModal({ open, clearOpen }) {
  const [, dispatch] = useReportTemplate();
  const appBridge = useContext(AppBridgeContext);
  const fetch = useUtilityFetch();

  const [pickerId, setPickerId] = useState(null);

  const loadProduct = useCallback(
    async (productID) => {
      if (productID) {
        return fetch(`${BASE_ROUTE}/products/${productID}.json`)
          .then((response) => response.json())
          .then((response) => Object.values(response)[0])
          .then((product) => fetchExtraData(fetch, product, "product"))
          .then((object) => dispatch({ type: "addShopifyData", value: object }));
      }
      return null;
    },
    [dispatch, fetch]
  );

  return (
    appBridge && (
      <ResourcePicker
        open={open}
        resourceType="Product"
        selectMultiple={1}
        initialSelectionIds={pickerId ? [{ id: `gid://shopify/Product/${pickerId}` }] : []}
        showDraft
        showDraftBadge
        showArchived
        showArchivedBadge
        showHidden
        showVariants={false}
        onCancel={clearOpen}
        onSelection={({ selection }) => {
          clearOpen();
          const id = selection[0].id.split("Product/")[1];
          setPickerId(id);
          loadProduct(id);
        }}
      />
    )
  );
}

const getData = async (mainItem, fetch) => {
  switch (mainItem) {
    case "article":
      console.log("todo");
      return [];
    case "order":
      return fetch(`${BASE_ROUTE}/${mainItem}s.json?status=any`)
        .then((response) => response.json())
        .then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            return [];
          }
          return Object.values(response)[0];
        });
    default:
      return fetch(`${BASE_ROUTE}/${mainItem}s.json`)
        .then((response) => response.json())
        .then((response) => Object.values(response)[0]);
  }
};

function NormalModal({ open, clearOpen }) {
  const [{ mainItem }, dispatch, { shopifyData }] = useReportTemplate();
  const fetch = useUtilityFetch();

  const [searchQuery, setSearchQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedID, setSelectedId] = useState([]);

  const saveShopifyData = useCallback(async () => {
    try {
      setLoading(true);
      const object = await fetchExtraData(
        fetch,
        data.find(({ id }) => id === selectedID[0]),
        mainItem
      );

      dispatch({ type: "addShopifyData", value: object });
      clearOpen();
    } finally {
      setLoading(false);
    }
  }, [fetch, data, mainItem, dispatch, clearOpen, selectedID]);

  const clearShopifyData = useCallback(() => {
    dispatch({ type: "addShopifyData", value: null });
    setSelectedId([]);
    clearOpen();
  }, [clearOpen, dispatch]);

  const searchAll = useCallback(async () => {
    setLoading(true);
    setData(await getData(mainItem, fetch));
    setLoading(false);
  }, [fetch, mainItem]);

  useEffect(() => {
    if (mainItem) {
      searchAll();
    }
  }, [mainItem, searchAll]);

  const searchOrderNames = useCallback(async () => {
    setLoading(true);
    fetch(`${BASE_ROUTE}/graphql.json`, {
      method: "POST",
      json: {
        query: `{ orders(first: 50, query: "name:${searchQuery}*") { edges { node { id name } } } }`,
      },
    })
      .then((response) => response.json())
      .then(async (response) => {
        const ids = response.data.orders.edges.reduce((prev, cur) => {
          const id = cur.node.id.split("Order/")[1];
          return [...prev, id];
        }, []);

        return fetch(`${BASE_ROUTE}/${mainItem}s.json?status=any&ids=${ids.join(",")}`).then((r) => r.json());
      })
      .then((orders) => {
        setData(Object.values(orders)[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetch, mainItem, searchQuery]);

  const onSelectionChange = useCallback((selectionType, toggleType, id) => {
    if (selectionType === "page") {
      return;
    }
    if (toggleType) {
      setSelectedId([id]);
    } else {
      setSelectedId([]);
    }
  }, []);

  const dataTable = useMemo(() => {
      let table;
      switch (mainItem) {
        case "order":
          table = (
            <IndexTable
              headings={[
                { title: "Order" },
                { title: "Date" },
                { title: "Customer" },
                { title: "Total" },
                { title: "Payment Status" },
                { title: "Fulfillment status" },
                { title: "Items" },
                { title: "Delivery method" },
                { title: "Tags" },
              ]}
              itemCount={data.length}
              selectedItemsCount={selectedID.length}
              onSelectionChange={onSelectionChange}
            >
              {data.map((item, index) => {
                const toUpperCase = (str = "") => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

                const financialBadge = () => {
                  switch (item.financial_status) {
                    case "authorized":
                    case "pending":
                      return <Badge progress="incomplete">Payment Pending</Badge>;
                    case "partially_paid":
                      return (
                        <Badge progress="partiallyComplete" status="warning">
                          Partially paid
                        </Badge>
                      );
                    case "partially_refunded":
                      return (
                        <Badge progress="incomplete">{`${toUpperCase(item.financial_status.split("_")[0])} ${toUpperCase(
                          item.financial_status.split("_")[1]
                        )}`}</Badge>
                      );
                    case "paid":
                      return <Badge progress="complete">Paid</Badge>;
                    default:
                      return <></>;
                  }
                };

                const fulfillmentBadge = () => {
                  switch (item.fulfillment_status) {
                    case "fulfilled":
                      return <Badge progress="complete">Fulfilled</Badge>;
                    case "partial":
                      return (
                        <Badge progress="partiallyComplete" status="warning">
                          Partially Fulfilled
                        </Badge>
                      );
                    case null:
                      return (
                        <Badge progress="incomplete" status="attention">
                          Unfufilled
                        </Badge>
                      );
                    default:
                      return <Badge>{toUpperCase(item.fulfillment_status)}</Badge>;
                  }
                };

                return (
                  <IndexTable.Row key={item.id} position={index} id={item.id} selected={selectedID.includes(item.id)}>
                    <IndexTable.Cell>{item.name}</IndexTable.Cell>
                    <IndexTable.Cell>{DateTime.fromISO(item.created_at).toFormat("DDD")}</IndexTable.Cell>
                    <IndexTable.Cell>{item.customer && `${item.customer?.first_name} ${item.customer?.last_name}`}</IndexTable.Cell>
                    <IndexTable.Cell>{item.total_price}</IndexTable.Cell>
                    <IndexTable.Cell>{financialBadge()}</IndexTable.Cell>
                    <IndexTable.Cell>{fulfillmentBadge()}</IndexTable.Cell>
                    <IndexTable.Cell>{item.line_items.length}</IndexTable.Cell>
                    <IndexTable.Cell>{item.shipping_lines.length > 0 && item.shipping_lines[0].title}</IndexTable.Cell>
                    <IndexTable.Cell>{item.tags}</IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          );
          break;
        case "customer":
          table = (
            <IndexTable
              headings={[
                { title: "Customer Name" },
                { title: "Note", hidden: true },
                { title: "Status" },
                { title: "Orders" },
                { title: "Spent" },
              ]}
              itemCount={data.length}
              selectedItemsCount={selectedID.length}
              onSelectionChange={onSelectionChange}
            >
              {data.map((item, index) => (
                <IndexTable.Row position={index} id={item.id} selected={selectedID.includes(item.id)}>
                  <IndexTable.Cell>{`${item.first_name} ${item.last_name}`}</IndexTable.Cell>
                  <IndexTable.Cell>
                    {item.note && (
                      <Tooltip content={item.note}>
                        <Icon source={NoteMajor} color="base" />
                      </Tooltip>
                    )}
                  </IndexTable.Cell>
                  <IndexTable.Cell>{item.marketing_opt_in_level && <Badge status="success">Subscribed</Badge>}</IndexTable.Cell>
                  <IndexTable.Cell>{item.orders_count}</IndexTable.Cell>
                  <IndexTable.Cell>{item.total_spent}</IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          );
          break;
        case "product":
          table = (
            <IndexTable
              itemCount={data.length}
              selectedItemsCount={selectedID.length}
              onSelectionChange={onSelectionChange}
              headings={[{ title: "Product" }, { title: "Status" }]}
            >
              {data.map((item, index) => (
                <IndexTable.Row position={index} id={item.id} selected={selectedID.includes(item.id)}>
                  <IndexTable.Cell>{item.title}</IndexTable.Cell>
                  <IndexTable.Cell>{item.status}</IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          );
          break;
        default:
          table = (
            <ReactJson
              src={data}
              onSelect={({ /* name, value, type, */ namespace }) => {
                setSelectedId(namespace[0]?.id);
              }}
            />
          );
      }

      return table;
    }, [data, mainItem, onSelectionChange, selectedID]);


  if (!Array.isArray(data)) {
    return (
      <EmptyState fullWidth>
        <p>An Error Occurred</p>
      </EmptyState>
    );
  }

  return (
    <Modal
      large
      open={open}
      onClose={clearOpen}
      primaryAction={{
        loading,
        disabled: selectedID.length === 0,
        content: `Use ${mainItem}`,
        onAction: saveShopifyData,
      }}
      secondaryActions={[{ content: "Clear", disabled: !shopifyData, destructive: true, onAction: clearShopifyData }]}
      title="Sample Data"
    >
      {mainItem === "order" && (
        <Modal.Section title={`Browse ${mainItem}`}>
          <TextField
            // key="templateEditor-quickadd-searchbox"
            placeholder="Order Name"
            prefix={<Icon source={SearchMinor} color="base" />}
            connectedRight={
              <Stack>
                <Button onClick={searchOrderNames}>Search</Button>
              </Stack>
            }
            value={searchQuery}
            onChange={setSearchQuery}
            clearButton
            onClearButtonClick={() => setSearchQuery("")}
          />
        </Modal.Section>
      )}
      <Modal.Section flush>{dataTable}</Modal.Section>
    </Modal>
  );
}

export const loadDefaultSampleData = async (mainItem, fetch) => {
  if (!mainItem || !["order", "product", "customer"].includes(mainItem)) {
    return null;
  }
  const data = await getData(mainItem, fetch);
  const object = data[0]; // TODO: use a better heuristic
  const extraData = await fetchExtraData(fetch, object, mainItem);
  return extraData;
};

export const useLoadDefaultSampleData = () => {
  const [{ mainItem }, dispatch, { shopifyData }] = useReportTemplate();
  const shopifyDataPresent = !!shopifyData;
  const fetch = useUtilityFetch();
  return useCallback(() => {
    if (!shopifyDataPresent) {
      loadDefaultSampleData(mainItem, fetch).then((object) => {
        dispatch({ type: "addShopifyData", value: object });
      });
    }
  }, [dispatch, fetch, mainItem, shopifyDataPresent]);
};

function DataBrowserModal({ open, clearOpen }) {
  const [{ mainItem }] = useReportTemplate();
  const [{ user }] = useProfile();

  return mainItem === "product" && !user.watchtower ? (
    <ProductModal open={open} clearOpen={clearOpen} />
  ) : (
    <NormalModal open={open} clearOpen={clearOpen} />
  );
}

export default DataBrowserModal;
