import React, { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { ContextualSaveBar as PolarisContextualSaveBar } from "@shopify/polaris";
import { ContextualSaveBar as AppBridgeContextualSaveBar } from "@shopify/app-bridge/actions";
import { Context as ShopifyContext } from "@shopify/app-bridge-react";

function ShopifySaveBar({ active, message, save, discard }) {
  const app = useContext(ShopifyContext);
  const [savePending, setSavePending] = useState(false);
  const [discardPending, setDiscardPending] = useState(false);

  const localSave = useCallback(() => {
    setSavePending(true);
    Promise.resolve(save()).finally(() => setSavePending(false));
  }, [save]);

  const localDiscard = useCallback(() => {
    setDiscardPending(true);
    Promise.resolve(discard()).finally(() => setDiscardPending(false));
  }, [discard]);

  const options = useMemo(
    () => ({
      message,
      saveAction: {
        disabled: savePending,
        loading: savePending,
      },
      discardAction: {
        disabled: discardPending,
        loading: discardPending,
        discardConfirmationModal: true,
      },
    }),
    [message, savePending, discardPending]
  );

  const [storedSaveBar, setStoredSaveBar] = useState(null);

  useEffect(() => {
    if (!app) return () => {};
    const saveBar = AppBridgeContextualSaveBar.create(app);
    setStoredSaveBar(saveBar);
    return () => {
      saveBar.unsubscribe();
      saveBar.dispatch(AppBridgeContextualSaveBar.Action.HIDE);
    };
  }, [app]);

  useEffect(() => {
    if (storedSaveBar) return storedSaveBar.subscribe(AppBridgeContextualSaveBar.Action.SAVE, localSave);
    return () => {};
  }, [storedSaveBar, localSave]);

  useEffect(() => {
    if (storedSaveBar) return storedSaveBar.subscribe(AppBridgeContextualSaveBar.Action.DISCARD, localDiscard);
    return () => {};
  }, [storedSaveBar, localDiscard]);

  useEffect(() => {
    if (storedSaveBar) {
      storedSaveBar.dispatch(active ? AppBridgeContextualSaveBar.Action.SHOW : AppBridgeContextualSaveBar.Action.HIDE);
      if (active) storedSaveBar.set(options);
    }
  }, [storedSaveBar, active, options]);

  return app === null && active ? (
    <PolarisContextualSaveBar
      message={message}
      saveAction={{
        onAction: save,
        loading: savePending,
        disabled: savePending,
      }}
      discardAction={{
        onAction: discard,
        loading: discardPending,
        disabled: discardPending,
      }}
    />
  ) : null;
}

export default ShopifySaveBar;
