/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from "react";

import { Button, Stack, TextField, Link, Modal, Heading } from "@shopify/polaris";

import { useLocation } from "react-router-dom";
import { AsyncButton, useCreateModal, useCreateToast, useProfile } from "admin-frontend"; // eslint-disable-line
import { useXporterApi } from "../ApiProvider";
import { today, yesterday } from "../dateUtil";
import DateField from "./DateField";

function RunNowModalContents({ dismiss, template, onRun }) {
  const xporterApi = useXporterApi();
  const createToast = useCreateToast();
  const createModal = useCreateModal();
  const location = useLocation();
  const [profile] = useProfile();
  const [customFieldValue, setCustomFieldValue] = useState("");
  const [nameFieldValue, setNameFieldValue] = useState(`${template.NAME} - ${today(profile).toFormat("d/M/y")}`);
  const [startDate, setStartDate] = useState(() => yesterday(profile));
  const [endDate, setEndDate] = useState(() => today(profile).minus({ second: 1 }));

  const handleCustomFieldChange = useCallback((value) => setCustomFieldValue(value), []);

  const tooManyQueueError = (object, objectCount) => (
    <p>
      This report requires {objectCount} {object}s which is over your current plan limit. Please add recommended filters or reduce
      your date range. Feel free to{" "}
      <Link
        url="https://support.moddapps.com/hc/en-us/requests/new"
        href="https://support.moddapps.com/hc/en-us/requests/new"
        external
      >
        contact
      </Link>{" "}
      support if you have any questions.
    </p>
  );
  const noConnectionError = `We are unable to connect to the report server, please try again in 30 seconds. Please reach out to support in chat below if you have any questions or if the problem persists.`;

  const whichError = useCallback(
    (error) => {
      const maxCount = 12; // this was for testing, but there is a disconnect between this and perl's estimate, it should be dynamic based on their plan.
      if (error === "unable to connect to server") return noConnectionError;

      try {
        const digest = JSON.parse(error);
        const topLevelItemCount = digest[digest.main_item].count;
        if (topLevelItemCount > maxCount) {
          return tooManyQueueError(digest.main_item.split("::").slice(-1)[0], topLevelItemCount, maxCount);
        }
      } catch (ex) {
        // fall through
      }
      return error;
    },
    [noConnectionError]
  );

  const runReport = useCallback(() => {
    console.log(`Submitting template to generate from ${startDate.toISO()} to ${endDate.toISO()}`);
    return xporterApi
      .generateReport(template.ID, {
        name: nameFieldValue,
        start: startDate.toFormat("yyyy-MM-dd'T'HH:mm:ss"),
        end: endDate.toFormat("yyyy-MM-dd'T'HH:mm:ss"),
        custom: customFieldValue,
      })
      .catch((payload) => {
        if (payload?.active_success === "queued" || payload?.active_success === "generated") {
          return Promise.resolve();
        }

        if (payload.error) {
          console.error("Error handling Submitting request", payload);
          const message = whichError(payload.error);
          createModal({ title: "Something went wrong queuing your report", render: () => <>{message}</> });
        } else {
          console.warn("xporterApi.generateReport rejected with no payload.error", payload);
        }

        return Promise.reject();
      })
      .then(() => {
        createToast({ content: "Queued to generate." });
        onRun?.();
      })
      .catch(() => {})
      .finally(() => {
        dismiss();
      });
  }, [
    startDate,
    endDate,
    xporterApi,
    template.ID,
    nameFieldValue,
    customFieldValue,
    createToast,
    onRun,
    whichError,
    createModal,
    dismiss,
  ]);

  let showStart = false;
  let showEnd = false;

  const temp = { ...template.CONDITIONS.computed_queries, ...template.CONDITIONS.queries };
  Object.values(temp).forEach((value) => {
    switch (value[0].operator) {
      case "Within Date Range":
        showStart = true;
        showEnd = true;
        break;
      case "Before Date":
        showEnd = true;
        break;
      case "After Date":
        showStart = true;
        break;
      default:
        break;
    }
  });
  return (
    <>
      {template.DESCRIPTION && (
        <Modal.Section>
          <Heading>Description</Heading>
          <p>{template.DESCRIPTION}</p>
        </Modal.Section>
      )}
      <Modal.Section>
        <Stack vertical>
          <TextField label="Report Name" value={nameFieldValue} onChange={setNameFieldValue} />
          {(showStart || showEnd) && (
            <Stack>
              {showStart && <DateField label="Start" value={startDate} onChange={setStartDate} />}
              {showEnd && <DateField label="End" value={endDate} onChange={setEndDate} />}
            </Stack>
          )}
          {template.CUSTOM != null && <TextField label="Custom" value={customFieldValue} onChange={handleCustomFieldChange} />}
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <Stack>
          <Stack.Item fill>
            {!location.pathname.match(`/templates/${encodeURIComponent(template.ID)}`) && (
              <Button
                onClick={dismiss}
                url={`/templates/${encodeURIComponent(template.ID)}?return_to=${encodeURIComponent(location.pathname)}`}
              >
                Customize
              </Button>
            )}
          </Stack.Item>
          <Stack.Item>
            <AsyncButton onClick={dismiss}>Cancel</AsyncButton>
          </Stack.Item>
          <Stack.Item>
            <AsyncButton primary onClick={runReport} disabled={(showStart && startDate == null) || (showEnd && endDate == null)}>
              Run
            </AsyncButton>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </>
  );
}

function useRunTemplate() {
  const createModal = useCreateModal();

  return ({ template, onRun }) => {
    createModal({
      title: template.NAME,
      render: (dismiss) => <RunNowModalContents dismiss={dismiss} template={template} onRun={onRun} />,
      sectioned: false,
      primaryAction: null,
    });
  };
}

export default useRunTemplate;
