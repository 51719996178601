/* eslint-disable react/jsx-props-no-spreading */
import "./ReportLayoutTable.scss";
import { Icon, Modal, Popover, Tooltip, Pagination, Button, Subheading, Stack } from "@shopify/polaris";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { SettingsMinor } from "@shopify/polaris-icons";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { UpgradeButton, FeatureGate } from "admin-frontend";
import { useReportTemplate } from "../../TemplateContext";
import LiquidRowModal from "./LiquidRowModal";

const SpreadsheetHeading = React.forwardRef(
  ({ children, contextMenu, subObject = false, lastSubObject = false, dragHandles = true, isModal = false }, ref) => {
    const [showActions, setShowActions] = useState(false);
    const clearShowActions = useCallback(() => setShowActions(false), []);
    const toggleShowActions = useCallback(() => setShowActions(!showActions), [showActions]);
    const button = (
      <div
        ref={ref}
        className={`report-layout-table__spreadsheet-heading ${
          subObject ? "report-layout-table__spreadsheet-heading--sub-object" : ""
        } ${lastSubObject ? "report-layout-table__spreadsheet-heading--last-sub-object" : ""} ${
          dragHandles ? "report-layout-table__spreadsheet-heading--has-drag-handles" : ""
        }`}
      >
        <div aria-hidden="true" onClick={toggleShowActions} className="report-layout-table__spreadsheet-heading-click-overlay" />
        {children}
        <button type="button" onClick={toggleShowActions} className="report-layout-table__spreadsheet-heading-disclosure">
          <div className="report-layout-table__settings-icon">
            <Icon source={SettingsMinor} color="base" />
          </div>
        </button>
      </div>
    );

    return contextMenu ? (
      <>
        {isModal ? (
          <>
            {button}
            <Modal open={showActions} onClose={toggleShowActions}>
              {contextMenu({ dismiss: clearShowActions })}
            </Modal>
          </>
        ) : (
          <Popover
            active={showActions}
            activator={button}
            preferredPosition="below"
            preferredAlignment="right"
            onClose={toggleShowActions}
          >
            {contextMenu({ dismiss: clearShowActions })}
          </Popover>
        )}
      </>
    ) : (
      <div ref={ref} className="report-layout-table__spreadsheet-heading">
        {children}
      </div>
    );
  }
);

function ReportLayoutTable({
  rows = [],
  columns = [],
  trailingColumns = [],
  columnDragEnd = () => {},
  rowDragEnd = () => {},
  rowDragCheck = () => true,
}) {
  const verticalDragMarker = useRef(null);
  const horizontalDragMarker = useRef(null);
  const scrollTable = useRef(null);
  const itemsRef = useRef([]);

  const [endCell, setEndCell] = useState(1);
  const [startCell, setStartCell] = useState(1);
  const [atMin, setAtMin] = useState(1);
  const [atMax, setAtMax] = useState(1);

  const getTableValues = useCallback(() => {
    const cellWidthCurrent = itemsRef.current[0].getBoundingClientRect().width;
    const scrollNumCurrent = Math.floor(scrollTable.current.clientWidth / cellWidthCurrent);

    const out = {
      cellWidth: cellWidthCurrent,
      scrollNum: scrollNumCurrent,
    };

    return out;
  }, [itemsRef, scrollTable]);

  const scrollLeftAction = useCallback(() => {
    const tableValues = getTableValues();
    const { cellWidth, scrollNum } = tableValues;
    scrollTable.current.scrollLeft -= cellWidth + (scrollTable.current.scrollLeft % cellWidth) + cellWidth * (scrollNum - 1);
  }, [scrollTable, getTableValues]);

  const scrollRightAction = useCallback(() => {
    const tableValues = getTableValues();
    const { cellWidth, scrollNum } = tableValues;
    scrollTable.current.scrollLeft += cellWidth - (scrollTable.current.scrollLeft % cellWidth) + cellWidth * (scrollNum - 1);
  }, [scrollTable, getTableValues]);

  const getStart = useCallback(() => {
    const tableValues = getTableValues();
    const { cellWidth } = tableValues;

    const currentWidth = scrollTable.current.scrollLeft;
    const currentCell = Math.floor(currentWidth / cellWidth);
    const myLetter = columns[currentCell].title;

    setStartCell(myLetter);
  }, [getTableValues, columns]);

  const getEnd = useCallback(() => {
    const tableValues = getTableValues();
    const { cellWidth, scrollNum } = tableValues;

    const currentWidth = scrollTable.current.scrollLeft;
    const currentCell = Math.floor(currentWidth / cellWidth);
    const end = Math.min(currentCell + scrollNum, columns.length - 1);

    setEndCell(columns[end].title);
  }, [getTableValues, columns]);

  const atStart = useCallback(() => {
    setAtMin(scrollTable.current.scrollLeft <= 0 ? 1 : 0);
  }, []);

  const atEnd = useCallback(() => {
    const endScroll = scrollTable.current.scrollWidth - scrollTable.current.clientWidth;
    setAtMax(scrollTable.current.scrollLeft >= endScroll ? 1 : 0);
  }, []);

  const updateScroll = useCallback(() => {
    getStart();
    getEnd();
    atStart();
    atEnd();
  }, [atEnd, atStart, getEnd, getStart]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateScroll();
    });
    if (scrollTable.current) resizeObserver.observe(scrollTable.current);
    return () => resizeObserver.disconnect();
  }, [updateScroll]);

  const setVerticalDragMarker = (index) => {
    if (verticalDragMarker.current) {
      if (index == null) {
        verticalDragMarker.current.style.left = "";
      } else {
        verticalDragMarker.current.style.left = `calc(${index} * var(--cell-width))`;
      }
    }
  };

  const setHorizontalDragMarker = (index, good = true) => {
    if (horizontalDragMarker.current) {
      if (index == null) {
        horizontalDragMarker.current.style.top = "";
      } else {
        horizontalDragMarker.current.style.top = `calc(${index} * var(--cell-height))`;
      }
      horizontalDragMarker.current.classList.toggle("report-layout-table__horizontal-drag-marker--error", !good && index != null);
    }
  };

  const localColumnDragStart = useCallback((result) => {
    setVerticalDragMarker(result?.source?.index);
  }, []);

  const localColumnDragUpdate = useCallback((result) => {
    setVerticalDragMarker(result?.destination?.index ?? result?.source?.index);
  }, []);

  const localColumnDragEnd = useCallback(
    (result) => {
      setVerticalDragMarker(null);
      columnDragEnd(result);
    },
    [columnDragEnd]
  );

  const localRowDragStart = useCallback((result) => {
    setHorizontalDragMarker(result?.source?.index);
  }, []);

  const localRowDragUpdate = useCallback(
    (result) => {
      setHorizontalDragMarker(result?.destination?.index ?? result?.source?.index, rowDragCheck(result));
    },
    [rowDragCheck]
  );

  const localRowDragEnd = useCallback(
    (result) => {
      setHorizontalDragMarker(null);
      rowDragEnd(result);
    },
    [rowDragEnd]
  );

  const [{ trailingRowsSuperheader, trailingRowsFooter }, dispatch] = useReportTemplate();

  const [addRowsPopoverActive, setAddRowsPopoverActive] = useState(false);
  const toggleAddRowsPopoverActive = useCallback(() => setAddRowsPopoverActive(!addRowsPopoverActive), [addRowsPopoverActive]);

  const [addLiquidRowModalActive, setAddLiquidRowModalActive] = useState(false);
  const toggleAddLiquidRowModal = useCallback(() => {
    setAddLiquidRowModalActive(!addLiquidRowModalActive);
  }, [addLiquidRowModalActive]);

  const setTrailingRowsSuperheader = (value) => dispatch({ type: "merge", value: { trailingRowsSuperheader: value } });
  const setTrailingRowsFooter = (value) => dispatch({ type: "merge", value: { trailingRowsFooter: value } });

  return (
    <>
      <div className="report-layout-table">
        <DragDropContext onDragStart={localRowDragStart} onDragUpdate={localRowDragUpdate} onDragEnd={localRowDragEnd}>
          <Droppable droppableId="column-headings" direction="vertical">
            {(droppableProvided) => (
              <div
                className="report-layout-table__heading-area"
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                <div className="report-layout-table__top-left-corner">
                  <SpreadsheetHeading />
                </div>

                {rows.map((row, index) => (
                  <Draggable key={row.id} draggableId={row.id} index={index} isDragDisabled={!!row.locked}>
                    {(draggableProvided) => (
                      <div
                        key={row.id}
                        className="report-layout-table__row-heading"
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <SpreadsheetHeading contextMenu={row.contextMenu} dragHandles={!row.locked} isModal>
                          {row.contextPath && (
                            <Tooltip content={row.contextPath}>
                              <div className="liquid-row-context">
                                <p>{row.title}</p>
                                <p className="small-text">{row.contextPath}</p>
                              </div>
                            </Tooltip>
                          )}
                          {!row.contextPath && (
                            <div style={{ display: "inline-block", width: "90%" }}>
                              <p>{row.title}</p>
                            </div>
                          )}
                        </SpreadsheetHeading>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
                <div
                  style={{
                    position: "absolute",
                    width: "20rem",
                    height: "4rem",
                    top: "100%",
                    left: "0",
                    padding: "0.2rem",
                  }}
                >
                  <Popover
                    active={addRowsPopoverActive}
                    onClose={toggleAddRowsPopoverActive}
                    activator={
                      <Button fullWidth disclosure onClick={toggleAddRowsPopoverActive}>
                        Add Header/Footer
                      </Button>
                    }
                    sectioned
                  >
                    <Stack vertical>
                      <Stack vertical spacing="extraTight">
                        <Subheading>Static Row</Subheading>
                        <Stack spacing="extraTight">
                          <Button
                            onClick={() => {
                              setTrailingRowsSuperheader([
                                { id: `extrarow-${+new Date()}`, contents: [] },
                                ...trailingRowsSuperheader,
                              ]);
                              toggleAddRowsPopoverActive();
                            }}
                          >
                            Header
                          </Button>
                          <Button
                            onClick={() => {
                              setTrailingRowsFooter([...trailingRowsFooter, { id: `extrarow-${+new Date()}`, contents: [] }]);
                              toggleAddRowsPopoverActive();
                            }}
                          >
                            Footer
                          </Button>
                        </Stack>
                      </Stack>
                      <FeatureGate featureName="liquid-fields" featureValue>
                        <Stack vertical spacing="extraTight">
                          <Subheading>Dynamic Row </Subheading>
                          <UpgradeButton
                            asPopover
                            onClick={() => {
                              toggleAddLiquidRowModal();
                            }}
                          >
                            Add Liquid Row
                          </UpgradeButton>
                        </Stack>
                      </FeatureGate>
                    </Stack>
                  </Popover>
                  <LiquidRowModal
                    open={addLiquidRowModalActive}
                    onClose={() => {
                      toggleAddLiquidRowModal();
                    }}
                  />
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="report-layout-table__data-area" ref={scrollTable} onScroll={updateScroll}>
          <div className="report-layout-table__table-wrapper">
            <table className="report-layout-table__table">
              <thead className="report-layout-table__head">
                <DragDropContext
                  onDragStart={localColumnDragStart}
                  onDragUpdate={localColumnDragUpdate}
                  onDragEnd={localColumnDragEnd}
                >
                  <Droppable droppableId="column-headings" direction="horizontal">
                    {(droppableProvided) => (
                      <tr
                        className="report-layout-table__row"
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                      >
                        {columns.map((column, index) => (
                          <Draggable key={column.id} draggableId={column.id} index={index}>
                            {(draggableProvided) => (
                              <th
                                key={column.id}
                                className="report-layout-table__column-heading"
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                              >
                                <SpreadsheetHeading
                                  ref={(el) => {
                                    itemsRef.current[index] = el;
                                  }}
                                  contextMenu={column.contextMenu}
                                >
                                  {column.title + (column.aggregation ? ` [${column.aggregation}]` : "")}
                                </SpreadsheetHeading>
                              </th>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                        {trailingColumns.map((column) => (
                          <th key={column.id} className="report-layout-table__column-heading">
                            <SpreadsheetHeading contextMenu={column.contextMenu}>{column.title}</SpreadsheetHeading>
                          </th>
                        ))}
                      </tr>
                    )}
                  </Droppable>
                </DragDropContext>
              </thead>
              <tbody className="report-layout-table__body">
                {rows.map((row) => (
                  <tr key={row.id} className="report-layout-table__row">
                    {row.items.map((item, index) =>
                      item.id === "data-rows" ? (
                        <td key={columns[index].id} className="report-layout-table__cell report-layout-table__double-height">
                          {item.content}
                        </td>
                      ) : (
                        <td key={columns[index].id} className="report-layout-table__cell">
                          {item.content}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div ref={verticalDragMarker} className="report-layout-table__vertical-drag-marker" />
            <div ref={horizontalDragMarker} className="report-layout-table__horizontal-drag-marker" />
          </div>
        </div>
      </div>
      <div className="report-layout-table__scroll">
        <Pagination
          hasNext={!atMax}
          hasPrevious={!atMin}
          onNext={scrollRightAction}
          onPrevious={scrollLeftAction}
          label={`${startCell} - ${endCell}`}
        />
      </div>
    </>
  );
}

export default ReportLayoutTable;
