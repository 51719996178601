import React, { useState, useCallback, useContext } from "react";
import { SkeletonBodyText, Card, Page, Stack, TextField, Button } from "@shopify/polaris";
import ReactJson from "react-json-view";
import { DateTime } from "luxon";

import { SubscriptionContext, useProfile, useCreateToast } from "admin-frontend";
import DateField from "./components/DateField";
import { useXporterApi } from "./ApiProvider";

function Debug() {
  const createToast = useCreateToast();
  const xporterApi = useXporterApi();

  const [profile, , invalidateProfile] = useProfile();
  const subscriptionContext = useContext(SubscriptionContext);

  const [date, setDate] = useState(DateTime.now().setZone(profile.shop.iana_timezone).startOf("second"));
  const [ids, setIDs] = useState();
  const [target, setTarget] = useState();

  const csMember = profile.user.email.includes("@moddapps.com") || profile.user.watchtower;

  const sendAPIRequest = useCallback(async () => {
    if(csMember) {
      try {
        const response = await xporterApi.duplicateTemplates({templateIds: ids, targetShop: target});
        createToast({ content: JSON.stringify(response) });
        console.log(response);
      } catch (ex) {
        console.log(JSON.stringify(ex));
        createToast({content: JSON.stringify(ex)});
      }
    }
    else{
      createToast({content: "You are not authorized to duplicate templates"});
    }
  }, [ids, target, xporterApi, createToast, csMember]);

  return (
    <Page title="Debug" breadcrumbs={[{ content: "Home", url: "/" }]}>
      {csMember ? <>
      <Card title="Duplicate Templates" sectioned >
        <TextField 
          label="IDs:"
          helpText="Enter a string of comma separated report ids, or the word 'all' to duplicate all templates"
          onChange={setIDs}
          value={ids}
        />
        <TextField 
          label="Target Store"
          helpText="Enter the .myshopify.com url of the target store to copy to"
          onChange={setTarget}
          value={target}
        />
        <Button onClick={sendAPIRequest} primary>Duplicate</Button>
      </Card>
      </> : null}

      <Card title="Debug" sectioned actions={[{ content: "Refresh", onAction: invalidateProfile }]}>
        {profile ? <ReactJson src={profile} /> : <SkeletonBodyText lines={8} />}
      </Card>
      <Card title="Subscriptions" sectioned actions={[{ content: "Refresh", onAction: () => subscriptionContext.reload(true) }]}>
        <ReactJson src={subscriptionContext} />
      </Card>

      <Card title="Debug" sectioned>
        <Stack vertical>
          <DateField value={date} onChange={setDate} />
          <DateField value={date} onChange={setDate} />
          <p style={{ textAlign: "right" }}>{date?.toISO() ?? "nullish"}</p>
        </Stack>
      </Card>
    </Page>
  );
}

export default Debug;
