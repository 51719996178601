/* eslint-disable jsx-a11y/anchor-is-valid */
import "./FileListingTable.scss";
import React, { useState, useCallback, useEffect, useMemo, useRef, useReducer } from "react";
import { Button, Icon, Spinner, Link, Stack, TextStyle, Checkbox, ButtonGroup, Heading } from "@shopify/polaris";
import { SaveMinor, ClockMinor, DeleteMinor } from "@shopify/polaris-icons";
import JSZip from "jszip";
import { useLocation } from "react-router-dom";
import { AsyncButton, useCreateModal } from "admin-frontend";
import { useXporterApi } from "../ApiProvider";

const MAX_TEXT_LENGTH = 40;
const RIGHT_ALIGN = { textAlign: "right" };

function DownloadStatus({ isBeingRun, downloadUrl }) {
  if (downloadUrl) {
    return (
      <Button onClick={() => window.open(downloadUrl)} plain>
        <Stack vertical spacing="extraTight">
          <Stack.Item>
            <Icon source={SaveMinor} />
          </Stack.Item>
          <Stack.Item>Save</Stack.Item>
        </Stack>
      </Button>
    );
  }
  if (isBeingRun) {
    return <Spinner accessibilityLabel="Report is being run" size="small" />;
  }
  return <Icon source={ClockMinor} />;
}

function ShortenedTextConditionalButton({ text = "", onClick, url, large }) {
  const innerContent = text.length > MAX_TEXT_LENGTH ? `${text.substr(0, MAX_TEXT_LENGTH - 1)}…` : text;

  const styledInnerContent = large ? <TextStyle variation="strong">{innerContent}</TextStyle> : innerContent;
  if (url) {
    return (
      <Link url={url} removeUnderline>
        {styledInnerContent}
      </Link>
    );
  }
  if (onClick) {
    return (
      <Button plain onClick={onClick}>
        {styledInnerContent}
      </Button>
    );
  }
  return (
    <Button plain disabled>
      {innerContent}
    </Button>
  );
}

function TableCell({ children, small = false }) {
  return <div className={`file-listing-table__cell ${small ? "file-listing-table__cell--small" : ""}`}>{children}</div>;
}

function FileListingTable({ items, selected, handleSelectionChange, bulkDelete, emptyState }) {
  const [columns, setColumns] = useState(1);
  const widthTesterDiv = useRef(null);
  const location = useLocation();

  const selectedItems = useMemo(() => items.filter((item) => selected.has(item.key)), [items, selected]);
  const selectedFiles = useMemo(() => selectedItems.filter((item) => item.type === "file"), [selectedItems]);

  const [bulkDeleteLoadingCount, dispatchBulkDeleteLoadingCount] = useReducer((last, action) => last + action, 0);
  const startLoading = useCallback(() => {
    dispatchBulkDeleteLoadingCount(1);
    return () => dispatchBulkDeleteLoadingCount(-1);
  }, []);

  const bulkDeleteWithLoading = useCallback(() => bulkDelete(startLoading), [bulkDelete, startLoading]);

  const selectedIsEmpty = selected.size === 0;

  const handleAllSelectionChange = useCallback(() => {
    handleSelectionChange(selectedIsEmpty, null);
  }, [handleSelectionChange, selectedIsEmpty]);

  const singleDownload = useCallback(() => {
    const [{ downloadUrl }] = selectedFiles;
    window.open(downloadUrl);
  }, [selectedFiles]);

  const bulkZip = useCallback(async () => {
    // this makes the browser download all of the selected files and zips it locally
    // this doesn't save bandwidth but it take the processing load off the server
    const zipFile = new JSZip();

    const usedNames = new Set();

    await Promise.all(
      selectedFiles.map(async ({ downloadUrl, name, fileFormat }) => {
        let archiveName = `${name}.${fileFormat}`.replace(/[/\\?%*:|"<>]/g, "_");
        let count = 1;

        while (usedNames.has(archiveName)) {
          count += 1;
          archiveName = `${name} ${count}.${fileFormat}`.replace(/[/\\?%*:|"<>]/g, "_");
        }

        usedNames.add(archiveName);

        const reponse = await fetch(downloadUrl);
        if (reponse.ok) {
          zipFile.file(archiveName, reponse.blob());
        }
      })
    );

    const zipFileBlob = await zipFile.generateAsync({ type: "blob" });

    const url = URL.createObjectURL(zipFileBlob);

    const otherWindow = window.open("");
    const link = otherWindow.document.createElement("a");
    link.href = url;
    link.download = `xporter_${+new Date()}.zip`;
    link.target = "_blank";
    link.innerHTML = "Download";
    otherWindow.document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      otherWindow.close();
      URL.revokeObjectURL(url);
    }, 1000);
  }, [selectedFiles]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([entry] = []) => {
      const width = entry?.borderBoxSize?.[0]?.inlineSize;
      if (width > 1500) {
        setColumns(5);
        return;
      }
      if (width > 1000) {
        setColumns(3);
        return;
      }
      setColumns(1);
    });
    if (widthTesterDiv.current) resizeObserver.observe(widthTesterDiv.current);
    return () => resizeObserver.disconnect();
  }, []);

  const headings = useMemo(
    () =>
      [
        [
          // 0: degenerate form
        ],
        [
          // 1: one large super cell with data aligned left and righ
          { title: "" },
        ],
        [
          // 2: degenerate form,
        ],
        [
          // 3: three columns mode
          { title: "File Name" },
          { title: "Created / Report" },
          { title: "Report Range" },
        ],
        [
          // 4: degenerate mode
        ],
        [
          // 5: everything mode
          { title: "File Name" },
          { title: "Created" },
          { title: "Report" },
          { title: "Start" },
          { title: "End" },
        ],
      ][columns],
    [columns]
  );

  const rowMapping = useCallback(
    (item) => {
      const { key, name, templateName, templateId, start, end, created, isBeingRun, downloadUrl, fileFormat, type } = item;

      const nameMarkup = (
        <ShortenedTextConditionalButton
          text={name}
          large={type === "file"}
          onClick={downloadUrl != null && (() => window.open(downloadUrl))}
        />
      );

      const templateMarkup = (
        <ShortenedTextConditionalButton
          text={templateName}
          url={templateId != null && `/templates/${templateId}?return_to=${location.pathname}`}
        />
      );

      const selectedCell = (
        <TableCell small key="selected">
          <Checkbox label="Selected" labelHidden id={key} checked={selected.has(key)} onChange={handleSelectionChange} />
        </TableCell>
      );

      const downloadCell = (
        <TableCell small key="download">
          <DownloadStatus isBeingRun={isBeingRun} downloadUrl={downloadUrl} fileFormat={fileFormat} />
        </TableCell>
      );

      const everythingCell = (
        <TableCell key="everything">
          <Stack vertical spacing="extraTight">
            <Stack.Item>{nameMarkup}</Stack.Item>
            <Stack distribution="fill">
              <Stack vertical spacing="extraTight">
                <Stack.Item>
                  {type === "file" ? (
                    <>Created: {created}</>
                  ) : (
                    <TextStyle variation="strong">REPORT {isBeingRun ? "PROCESSING" : "QUEUED"}</TextStyle>
                  )}
                </Stack.Item>
                <Stack.Item>From: {templateMarkup}</Stack.Item>
              </Stack>

              <Stack vertical spacing="extraTight">
                <Stack.Item>
                  <div style={RIGHT_ALIGN}>Start: {start}</div>
                </Stack.Item>
                <Stack.Item>
                  <div style={RIGHT_ALIGN}>End: {end}</div>
                </Stack.Item>
              </Stack>
            </Stack>
          </Stack>
        </TableCell>
      );

      const nameCell = <TableCell key="name">{nameMarkup}</TableCell>;

      const createdAndTemplateCell = (
        <TableCell key="createdAndTemplate">
          <Stack vertical spacing="extraTight">
            <Stack.Item>{created}</Stack.Item>
            <Stack.Item>{templateMarkup}</Stack.Item>
          </Stack>
        </TableCell>
      );

      const rangeCell = (
        <TableCell key="range">
          <Stack vertical spacing="extraTight">
            <Stack.Item>{start}</Stack.Item>
            <Stack.Item>{end}</Stack.Item>
          </Stack>
        </TableCell>
      );

      const createdCell = <TableCell key="created">{created}</TableCell>;
      const templateCell = <TableCell key="templateMarkup">{templateMarkup}</TableCell>;
      const startCell = <TableCell key="start">{start}</TableCell>;
      const endCell = <TableCell key="end">{end}</TableCell>;

      const deleteCell = (
        <TableCell small key="delete">
          <Button plain icon={DeleteMinor} destructive onClick={item.delete} />
        </TableCell>
      );

      const cellData = [
        [],
        [selectedCell, downloadCell, everythingCell, deleteCell],
        [],
        [selectedCell, downloadCell, nameCell, createdAndTemplateCell, rangeCell, deleteCell],
        [],
        [selectedCell, downloadCell, nameCell, createdCell, templateCell, startCell, endCell, deleteCell],
      ][columns];

      return (
        <div key={key} className={`file-listing-table__row ${selected.has(key) ? "file-listing-table__row--selected" : ""}`}>
          {cellData}
        </div>
      );
    },
    [columns, handleSelectionChange, location.pathname, selected]
  );

  /*    selectedItemsCount={selectedItems.length}
      onSelectionChange={handleSelectionChange}
      bulkActions={[]}
      loading={loadingCount > 0}
      
      headings={headings}> */
  return (
    <div ref={widthTesterDiv}>
      <div className="file-listing-table__header-row">
        <TableCell small>
          <Checkbox
            id="all"
            checked={selectedItems.length === items.length || (selectedItems.length > 0 && "indeterminate")}
            onChange={handleAllSelectionChange}
            label="Select All"
            labelHidden
          />
        </TableCell>

        {selectedItems.length > 0 ? (
          <TableCell>
            <ButtonGroup segmented>
              <Button onClick={bulkDeleteWithLoading} loading={bulkDeleteLoadingCount > 0}>
                Delete {selectedItems.length > 1 ? `${selectedItems.length} files` : "file"}
              </Button>
              {selectedFiles.length > 1 && (
                <AsyncButton onClick={bulkZip}>Save {selectedItems.length} files as .zip File</AsyncButton>
              )}
              {selectedFiles.length === 1 && <AsyncButton onClick={singleDownload}>Save File</AsyncButton>}
            </ButtonGroup>
          </TableCell>
        ) : (
          <>
            <TableCell small />
            {headings.map(({ title }) => (
              <TableCell key={title}>
                <Heading>{title}</Heading>
              </TableCell>
            ))}
            <TableCell small />
          </>
        )}
      </div>
      {items.length === 0 ? emptyState : items.map(rowMapping)}
    </div>
  );
}

export const normalizeQueueResponse = (endpointQueueResponse) =>
  endpointQueueResponse.map((apiObject) => {
    if (apiObject == null) {
      return null;
    }
    const {
      ID: id,
      NAME: name,
      TEMPLATE: templateName,
      START: start,
      END: end,
      CREATED: created,
      STATUS_PENDING: isBeingRun,
    } = apiObject;
    return {
      key: `q${id}`,
      id,
      name,
      templateName,
      templateId: null,
      start,
      end,
      created,
      isBeingRun: !!isBeingRun,
      type: "queue",
    };
  });

export const normalizeFileListingResponse = (endpointFileListingResponse) =>
  endpointFileListingResponse.map((apiObject) => {
    if (apiObject == null) {
      return null;
    }
    const {
      REPORT_ID: id,
      NAME: name,
      TEMPLATE_NAME: templateName,
      TEMPLATE_ID: templateId,
      START: start,
      END: end,
      DATE: created,
      URL: downloadUrl,
      FILETYPE: fileFormat,
    } = apiObject;
    return {
      key: `r${id}`,
      id,
      name,
      templateName,
      templateId,
      start,
      end,
      created,
      isBeingRun: false,
      downloadUrl,
      fileFormat,
      type: "file",
    };
  });

export const useCancelPendingReport = () => {
  const xporterApi = useXporterApi();
  const createModal = useCreateModal();

  return useCallback(
    (queueItem) => {
      if (!queueItem) return;
      createModal({
        title: "Cancel Job",
        primaryAction: {
          destructive: true,
          content: "Cancel Job",
          onAction: () =>
            xporterApi.removeQueueItem(queueItem.id).catch((ex) => console.warn("xporterApi.removeQueueItem() rejected with", ex)),
        },
        secondaryActions: [
          {
            content: "Close",
          },
        ],
        render() {
          return (
            <Stack vertical>
              <p>
                <strong>File Name:</strong> {queueItem.name} (from{" "}
                {queueItem.templateName.slice(queueItem.templateName.indexOf("/") + 1)})
                {queueItem.start && (
                  <>
                    <br />
                    <strong>From:</strong> {queueItem.start}
                  </>
                )}
                {queueItem.end && (
                  <>
                    <br />
                    <strong>To:</strong> {queueItem.end}
                  </>
                )}
              </p>
              <p>
                <TextStyle variation="negative">Are you sure you want to cancel this job? This action cannot be undone.</TextStyle>
              </p>
            </Stack>
          );
        },
      });
    },
    [createModal, xporterApi]
  );
};

export const useDeleteFile = () => {
  const xporterApi = useXporterApi();
  const createModal = useCreateModal();

  return useCallback(
    (fileListingItem) => {
      if (!fileListingItem) return;
      createModal({
        title: "Delete File",
        primaryAction: {
          destructive: true,
          content: "Delete File",
          onAction: () => xporterApi.deleteReports([fileListingItem.id]),
        },

        render() {
          return (
            <Stack vertical>
              <p>
                <strong>File Name:</strong> {fileListingItem.name} (from{" "}
                {fileListingItem.templateName.slice(fileListingItem.templateName.indexOf("/") + 1)})
                {fileListingItem.start && (
                  <>
                    <br />
                    <strong>From:</strong> {fileListingItem.start}
                  </>
                )}
                {fileListingItem.end && (
                  <>
                    <br />
                    <strong>To:</strong> {fileListingItem.end}
                  </>
                )}
              </p>
              <p>
                <TextStyle variation="negative">Are you sure you want to delete this file? This action cannot be undone.</TextStyle>
              </p>
            </Stack>
          );
        },
      });
    },
    [createModal, xporterApi]
  );
};
export default FileListingTable;
