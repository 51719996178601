import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useProfile } from "admin-frontend";
import { useReportTemplate } from "../TemplateContext";
import useRunTemplate from "./useRunTemplate";
import ShopifySaveBar from "./ShopifySaveBar";
import { useXporterApi } from "../ApiProvider";
import { toPerlDate } from "../dateUtil";

export const generateObject = (
  {
    name,
    description,
    reportFields,
    reportFilters,
    keeps,
    reportLiquidRows,
    reportGeneration,
    reportFormat,
    mainItem,
    reportTweaks,
    reportPostSorting,
    reportPreSorting,
    xmlNamesSingular = {},
    xmlNamesPlural = {},
    xmlDoctype = "",
    trailingRowsFooter = [],
    trailingRowsHeader = [],
    trailingRowsSuperheader = [],
    reportEval,
    reportSignature,
  },
  { profile }
) => ({
  name,
  description,
  report_fields: reportFields,
  queries: reportFilters
    .filter((reportFilter) => reportFilter.id.split("-")[0] === mainItem)
    .map((query) => ({ ...query, conditions: query.conditions.map(({ id, ...rest }) => rest) })),
  computed_queries: reportFilters
    .filter((reportFilter) => reportFilter.id.split("-")[0] !== mainItem)
    .map((query) => ({ ...query, conditions: query.conditions.map(({ id, ...rest }) => rest) })),
  keeps,
  report_liquid_rows: Object.fromEntries(
    reportLiquidRows.map(({ id, contents, ...rest }) => [
      id,
      {
        ...rest,
        contents: reportFields.map((reportField) => contents.find((content) => content.id === reportField.id)?.value || ""),
      },
    ])
  ),
  report_generation: reportGeneration
    ? { ...reportGeneration, report_next_generation: toPerlDate(reportGeneration?.report_next_generation, profile) }
    : null,
  format: {
    ...reportFormat,
    ...(Object.keys(xmlNamesSingular).length > 0 && { item_singular_names: xmlNamesSingular }),
    ...(Object.keys(xmlNamesPlural).length > 0 && { item_plural_names: xmlNamesPlural }),
    ...(xmlDoctype && { doctype: xmlDoctype }),
  },
  tweaks: {
    ...reportTweaks,
    trailing_rows: {
      footer: trailingRowsFooter.map((footerRow) =>
        reportFields.map((reportField) => footerRow.contents.find((content) => content.id === reportField.id)?.value || "")
      ),
      header: trailingRowsHeader.map((headerRow) =>
        reportFields.map((reportField) => headerRow.contents.find((content) => content.id === reportField.id)?.value || "")
      ),
      superheader: trailingRowsSuperheader.map((superheaderRow) =>
        reportFields.map((reportField) => superheaderRow.contents.find((content) => content.id === reportField.id)?.value || "")
      ),
    },
  },
  report_sorting: reportPostSorting,
  report_pre_sorting: reportPreSorting,
  report_eval: reportEval,
  report_eval_signature: reportSignature,
});

export const useSaveTemplate = () => {
  const [profile] = useProfile();
  const history = useHistory();
  const location = useLocation();
  const xporterAPI = useXporterApi();
  const [currentState, dispatch, { id: templateId, error }] = useReportTemplate();
  const runTemplateModal = useRunTemplate();
  return async ({ runNow = false } = {}) => {
    if (error.length > 0) {
      dispatch({ type: "showErrors", value: true });
      return;
    }

    const data = Object.fromEntries(
      currentState.reportFields
        .filter((field) => Object.prototype.hasOwnProperty.call(field, "liquid"))
        .map(({ id, liquid }) => [id, liquid])
    );

    if (Object.keys(data).length) {
      const errors = await xporterAPI.validateLiquid(data);
      if (errors) {
        dispatch({ type: "injectLiquidErrors", value: errors });
      }
    }

    try {
      const payload = await xporterAPI.saveTemplate(templateId, generateObject(currentState, { profile }));

      if (payload.id) {
        // this fools the reducer in thinking the save template is the same as the original on the page
        // we want this because when a starter template is modified and then saved it's technically a different page with different contents
        dispatch({ type: "commit", value: { id: payload.id } });
        if (String(payload.id) !== String(templateId)) {
          history.replace(`/templates/${payload.id}${location.search}${location.hash}`, history.state);
        }
      }

      if (runNow) {
        const templates = await xporterAPI.getAllTemplates();
        runTemplateModal({ template: templates.find(({ ID }) => ID === Number(payload.id)) });
      }
    } catch (response) {
      try {
        if (response.error) {
          dispatch({ type: "appendErrors", value: [response.error] });
        } else {
          console.error("Unhandled error in Save Action");
          console.error(response);
        }
      } catch (ex) {
        console.error(response, ex);
      }
    }
  };
};

function TemplateSaveBar() {
  const [, dispatch, { unsavedChanges }] = useReportTemplate();
  const saveTemplate = useSaveTemplate();

  return (
    <ShopifySaveBar
      active={unsavedChanges}
      message="Unsaved changes"
      save={saveTemplate}
      discard={() => {
        dispatch({ type: "discard" });
      }}
    />
  );
}

export default TemplateSaveBar;
