import React from "react";
import ReactDOM from "react-dom";
import {
  HomeMajor,
  OrdersMajor,
  BugMajor,
  ReportsMajor,
  PageMajor,
  TemplateMajor,
  ViewMajor,
  HintMajor,
  InstallMinor,
} from "@shopify/polaris-icons";

import "./index.scss";

import { App, Subscriptions, InstallPage, Redirect } from "admin-frontend";
import "admin-frontend/dist/esm/styles.css";

import Home from "./Home";
import Debug from "./Debug";
import DebugMode from "./DebugMode";
import FileManager from "./FileManager";
import TemplateEditor from "./TemplateEditor";
import Privacy from "./Privacy";
import Faq from "./Faq";
import QuickRunReportsPage from "./QuickRunReportsPage";
import ReportsPage from "./ReportsPage";
import { ApiProvider } from "./ApiProvider";

// eslint-disable-next-line no-console
console.log("🦄");

const urlParams = new URLSearchParams(window.location.search);
const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY;

let urlToken = urlParams.get("token");
const shopOrigin = urlParams.get("shop");
const host = urlParams.get('host');

try {
  if (urlParams.has("persist") && urlToken) {
    sessionStorage.setItem("jwtSessionToken", urlToken);
  } else {
    urlToken = urlToken || sessionStorage.getItem("jwtSessionToken");
  }
} catch (ex) {
  // session storage didn't work
}

function GlobalContext({ children }) {
  return (
    <DebugMode>
      <ApiProvider>{children}</ApiProvider>
    </DebugMode>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App
      apiKey={apiKey}
      shopOrigin={shopOrigin}
      urlToken={urlToken}
      noAppBridgeNavigation
      globalContextComponent={GlobalContext}
      host={host}
      routes={[
        { name: "Overview", icon: HomeMajor, exact: true, path: "/", render: () => <Home /> },
        {
          exact: true,
          path: "/",
          access: "anonymous",
          render: () => <Redirect to="https://apps.shopify.com/xporter-by-eshop-admin" />,
        },
        { name: "Report Library", icon: ReportsMajor, path: "/template_library", render: () => <ReportsPage /> },
        { name: "Quick Run", icon: ReportsMajor, path: "/run_template", render: () => <QuickRunReportsPage /> },
        { name: "File Manager", icon: PageMajor, path: "/files", render: () => <FileManager /> },
        { name: "Template Editor", icon: TemplateMajor, exact: true, path: "/templates", render: () => <TemplateEditor /> },
        { name: "Template", icon: TemplateMajor, path: "/templates/:templateId", render: () => <TemplateEditor /> },
        { name: "Account", icon: OrdersMajor, path: "/account", render: () => <Subscriptions /> },
        { name: "Privacy", icon: ViewMajor, path: "/privacy", access: "all", render: () => <Privacy /> },
        { name: "FAQ", icon: HintMajor, path: "/faq", access: "all", render: () => <Faq /> },
        { name: "Debug", icon: BugMajor, path: "/debug", access: "all", render: () => <Debug /> },
        { name: "Install", icon: InstallMinor, path: "/login", access: "anonymous", render: () => <InstallPage /> },
      ]}
      onProfileIsKnown={(profile) => {
        try {
          window.dataLayer.push({
            shop_origin: profile.shop.shop_origin,
            shopify_plan: profile.shop.shopify_plan,
            shop_timezone: profile.shop.iana_timezone,
            days_installed: profile.shop.days_installed,
          });
        } catch (ex) {
          // do nothing
        }
      }}
    />
  </React.StrictMode>,
  document.getElementById("root")
);
