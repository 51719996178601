import { Button, Icon, Stack } from "@shopify/polaris";
import React from "react";
import { FeatureGate } from "admin-frontend";
import { CalendarMajor } from "@shopify/polaris-icons";
import { useReportTemplate } from "../../TemplateContext";

const textJoin = (phrases) => {
  const cleanedPhrases = phrases.filter((d) => d);
  if (cleanedPhrases.length < 2) {
    return cleanedPhrases.join();
  }
  return `${cleanedPhrases.slice(0, -1).join(", ")}, and ${cleanedPhrases[cleanedPhrases.length - 1]}`;
};

function AutoGenLine({ openAutoGenModal }) {
  const [{ reportGeneration }] = useReportTemplate();
  const enableGen = reportGeneration != null;
  const {
    ftp: Ftp,
    automatic_saving: saveServer,
    duration_number: durationNumber,
    duration_type: durationType,
  } = reportGeneration || {};

  const nextGeneration = reportGeneration?.report_next_generation;

  const showEmail = reportGeneration?.email != null;
  const showFtp = reportGeneration?.ftp != null;

  return (
    <FeatureGate featureName="automatic-generation" featureValue={1}>
      <Stack alignment="center">
        <Stack.Item>
          <Icon color="subdued" source={CalendarMajor} />
        </Stack.Item>
        <Stack.Item>
          {enableGen ? (
            <Stack vertical spacing="extraTight">
              <Stack.Item>Next Scheduled: {nextGeneration ? nextGeneration.toFormat("FF z") : "not set"}</Stack.Item>
              <Stack.Item>
                {durationNumber || durationType ? `Every ${durationNumber} ${durationType}` : null},{" "}
                {textJoin([
                  saveServer && "saved to the app",
                  showEmail &&
                    `emailed to ${reportGeneration.email.emails.length} ${
                      reportGeneration.email.emails.length === 1 ? "recipient" : "recipients"
                    }`,
                  showFtp && `stored on the ${Ftp.protocol} server ${Ftp.hostname}`,
                ])}{" "}
                <Button plain onClick={openAutoGenModal}>
                  Edit Schedule
                </Button>{" "}
              </Stack.Item>
            </Stack>
          ) : (
            <p>
              <Button plain onClick={openAutoGenModal}>
                Schedule this report
              </Button>{" "}
              to run it automatically
            </p>
          )}
        </Stack.Item>
      </Stack>
    </FeatureGate>
  );
}

export default AutoGenLine;
