/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Page, Layout, Stack, Heading, Card, Button, Link } from "@shopify/polaris";
import { SubscriptionContext } from "admin-frontend";
import { useXporterApi } from "./ApiProvider";
import { LargeReportList, LargeReportListGroups } from "./components/ReportsList";

const FAKE_INFINITY_REPORT_COUNT = 999999999;
const ALWAYS_SHOW_REPORT_COUNT_THRESHOLD = 5;

function ReportsPage() {
  const xporterApi = useXporterApi();
  const [templates, setTemplates] = useState([]);

  const subscription = useContext(SubscriptionContext);

  const maxTemplates = subscription.activePlan?.features["max-templates"].value;

  const templateCount = templates?.length || 0;

  const shouldShowCount =
    maxTemplates < FAKE_INFINITY_REPORT_COUNT || maxTemplates - templateCount < ALWAYS_SHOW_REPORT_COUNT_THRESHOLD;

  const refreshItems = useCallback(() => xporterApi.getTemplates().then(setTemplates), [xporterApi]);

  useEffect(() => {
    refreshItems();
  }, [refreshItems]);

  return (
    <Page
      fullWidth
      title="Report Library"
      subtitle="Below you will find your custom reports, as well as our library of starters. Click a report to run or customize"
      breadcrumbs={[{ content: "Overview", url: "/" }]}
      primaryAction={{ content: "New Report", url: "/templates" }}
    >
      <Layout>
        <Layout.Section>
          <Stack vertical>
            <Heading>Custom Reports</Heading>
            {shouldShowCount ? (
              <>
                {templateCount} of {maxTemplates} used
                {templateCount >= maxTemplates && (
                  <>
                    {" "}
                    (<Link url="/account">upgrade for more</Link>)
                  </>
                )}
              </>
            ) : (
              <>{templateCount} used of unlimited</>
            )}
            {templates?.length > 0 ? (
              <LargeReportList alwaysOpen items={templates} custom refreshItems={refreshItems} />
            ) : (
              <Card sectioned>
                <Button url="/templates" fullWidth>
                  Create your first custom report
                </Button>
              </Card>
            )}
            <Heading>Starter Reports</Heading>
            <LargeReportListGroups title="Starter Reports" hasCustomTemplates={templates?.length > 0}>
              Starter Reports are reports we&apos;ve created to help get you started; edit one to customize it to your needs.
            </LargeReportListGroups>
          </Stack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default ReportsPage;
