/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, useCallback, useEffect, useContext, useRef } from "react";
import { Button, Select, TextField, Stack, Icon, Modal, Label, Subheading } from "@shopify/polaris";
import { Context as AppBridgeContext, ResourcePicker } from "@shopify/app-bridge-react";

import { DeleteMajor, MaximizeMajor, CollectionsMajor, FilterMajor } from "@shopify/polaris-icons";

import { useProfile } from "admin-frontend";
import LiquidEditorModal from "../LiquidEditorModal";
import { FIELD_QUERY_TYPES, LIQUID_FILTER_OPERATORS } from "./values";
import { useReportTemplate } from "../../TemplateContext";
import DateField from "../DateField";
import { fromPerlDate, toPerlDate } from "../../dateUtil";

const MAP_CACHE_TOO_BIG_SIZE = 4096;

function PerlFilterDateField({ value, onChange, error }) {
  const [profile] = useProfile();

  const valueCache = useRef(new Map());

  const localValue = valueCache.current.get(value) || fromPerlDate(value, profile);

  const localOnChange = useCallback(
    (date) => {
      const perlDate = toPerlDate(date, profile);
      if (valueCache.current.size > MAP_CACHE_TOO_BIG_SIZE) {
        valueCache.current.clear();
      }
      valueCache.current.set(perlDate, date);
      onChange(perlDate);
    },
    [onChange, profile]
  );

  return <DateField value={localValue} onChange={localOnChange} error={error} />;
}

function RenderOperand({ fieldQueryType, condition, setCondition, active, setActive, showError }) {
  const [isResourcePickerOpen, setIsResourcePickerOpen] = useState(false);
  const appBridge = useContext(AppBridgeContext);

  const onChange = useCallback((value) => setCondition({ ...condition, operand: value }), [condition, setCondition]);

  const suffix = useMemo(
    () =>
      (condition.operator === "regex" ||
        condition.operator === "not regex" ||
        LIQUID_FILTER_OPERATORS.includes(condition.operator)) && (
        <Button plain monochrome onClick={() => setActive(!active)}>
          <Icon source={MaximizeMajor} />
        </Button>
      ),
    [active, condition.operator, setActive]
  );

  const operandTextBox =
    fieldQueryType?.operandType === "datetime-local" ? (
      <PerlFilterDateField value={condition.operand} onChange={onChange} error={showError && !condition.operand} />
    ) : (
      <TextField
        type={fieldQueryType && fieldQueryType?.operandType}
        value={condition.operand}
        onChange={onChange}
        error={showError && !condition.operand}
        placeholder="value"
        suffix={suffix}
      />
    );

  const operandVariantPicker = appBridge ? (
    <>
      <ResourcePicker
        resourceType="ProductVariant"
        open={isResourcePickerOpen}
        showHidden
        showDraft
        showDraftBadge
        showArchived
        showArchivedBadge
        allowMultiple={false}
        selectMultiple={false}
        onSelection={(data) => {
          setIsResourcePickerOpen(false);
          setCondition({ ...condition, operand: data.selection[0].id.split("/").pop() });
        }}
        onCancel={() => setIsResourcePickerOpen(false)}
      />
      <TextField value={condition.operand} readOnly onFocus={() => setIsResourcePickerOpen(true)} />
    </>
  ) : (
    operandTextBox
  );

  const operandProductPicker = appBridge ? (
    <>
      <ResourcePicker
        resourceType="Product"
        open={isResourcePickerOpen}
        showHidden
        allowMultiple={false}
        selectMultiple={false}
        showVariants={false}
        initialSelectionIds={condition.operand ? [{ id: `gid://shopify/Product/${condition.operand}` }] : []}
        showDraft
        showDraftBadge
        showArchived
        showArchivedBadge
        onSelection={(data) => {
          setIsResourcePickerOpen(false);
          setCondition({ ...condition, operand: data.selection[0].id.split("/").pop() });
        }}
        onCancel={() => setIsResourcePickerOpen(false)}
      />
      <TextField value={condition.operand} readOnly onFocus={() => setIsResourcePickerOpen(true)} />
    </>
  ) : (
    operandTextBox
  );

  const operandCollectionPicker = appBridge ? (
    <>
      <ResourcePicker
        resourceType="Collection"
        open={isResourcePickerOpen}
        showHidden
        allowMultiple={false}
        selectMultiple={false}
        onSelection={(data) => {
          setIsResourcePickerOpen(false);
          console.log(data.selection[0].id);
          setCondition({ ...condition, operator: data.selection[0].id.split("/").pop(), operand: "" });
        }}
        onCancel={() => setIsResourcePickerOpen(false)}
      />
      <TextField
        value={condition.operator}
        readOnly
        onFocus={() => setIsResourcePickerOpen(true)}
        prefix={<Icon source={CollectionsMajor} />}
        placeholder="click here to add a collection filter"
      />
    </>
  ) : (
    operandTextBox
  );

  const operandSelect = (
    <Select
      options={fieldQueryType.options}
      value={condition.operand}
      onChange={(value) => setCondition({ ...condition, operand: value })}
      error={!condition.operand}
      placeholder="value"
    />
  );

  // If the operator is one of these then we don't need an operand
  // is null
  // is not null
  // special date filter (before date, after date, within date range)
  if (
    fieldQueryType &&
    ((fieldQueryType.type === "datetime" && !(condition.operator === ">=" || condition.operator === "<=")) ||
      condition.operator === "is not null" ||
      condition.operator === "is null")
  ) {
    return <></>;
  }

  if (fieldQueryType?.type === "collection") {
    return operandCollectionPicker;
  }
  if (fieldQueryType?.type === "product_id" && condition.operator?.match(/^(!|=)/)) {
    return operandProductPicker;
  }
  if (fieldQueryType?.type === "variant_id" && condition.operator?.match(/^(!|=)/)) {
    return operandVariantPicker;
  }
  if (fieldQueryType?.type === "select") {
    return operandSelect;
  }

  return operandTextBox;
}

function FilterCondition({ condition, setCondition, fieldQueryType, showError }) {
  const [active, setActive] = useState(false);
  const [input, setInput] = useState(condition.operand);

  const handleClose = useCallback(() => {
    setActive(!active);
  }, [active]);

  const handleSave = useCallback(
    (value) => {
      setCondition({ ...condition, operand: value });
      setActive(!active);
    },
    [active, condition, setCondition]
  );

  useEffect(() => {
    setInput(condition.operand);
  }, [condition.operand]);

  const showOperatorField = useMemo(
    () => !LIQUID_FILTER_OPERATORS.includes(condition.operator) && fieldQueryType && fieldQueryType.type !== "collection",
    [condition.operator, fieldQueryType]
  );

  const fieldQueryOptions = useMemo(
    () => (fieldQueryType && [{ label: "", value: "", disabled: true }, ...fieldQueryType.operators]) || [],
    [fieldQueryType]
  );

  const renderOperator = showOperatorField ? (
    <Select
      fullWidth
      label={condition.operator ? undefined : "Choose an option"}
      labelInline={!condition.operator}
      options={fieldQueryOptions}
      onChange={(value) => setCondition({ ...condition, operator: value })}
      value={condition.operator}
      error={!condition.operator}
    />
  ) : null;

  return (
    <Stack vertical spacing="extraTight">
      {LIQUID_FILTER_OPERATORS.includes(condition.operator) && (
        <Stack.Item>
          <Label>evaluates the following to true</Label>
        </Stack.Item>
      )}
      <Stack spacing="extraTight" alignment="center" wrap={false}>
        {showOperatorField && <Stack.Item fill>{renderOperator}</Stack.Item>}
        <Stack.Item fill>
          <RenderOperand
            fieldQueryType={{ ...fieldQueryType }}
            condition={condition}
            setCondition={setCondition}
            active={active}
            setActive={setActive}
            showError={showError}
          />
        </Stack.Item>
        <Stack.Item>
          <Button icon={DeleteMajor} monochrome onClick={() => setCondition(null)} />
        </Stack.Item>
      </Stack>
      {(condition.operator === "regex" || condition.operator === "not regex") && (
        <Modal
          open={active}
          titleHidden
          onClose={handleClose}
          primaryAction={{
            content: "Save",
            onAction: () => {
              handleSave(input);
            },
          }}
          secondaryActions={[
            {
              content: "Discard",
              onAction: handleClose,
            },
          ]}
        >
          <Modal.Section>
            <TextField multiline={8} value={input} onChange={setInput} />
          </Modal.Section>
        </Modal>
      )}
      {LIQUID_FILTER_OPERATORS.includes(condition.operator) && (
        <LiquidEditorModal content={input} open={active} setOpen={setActive} onSave={handleSave} error={null} />
      )}
    </Stack>
  );
}

function FilterRow({ field, setField, objectFields, showError }) {
  const { id } = field;

  const fieldRow = useMemo(() => objectFields.find(({ id: innerId }) => innerId === id), [id, objectFields]);
  const fieldQueryType = useMemo(() => {
    let localFieldQueryType = (
      fieldRow ? 
      FIELD_QUERY_TYPES.find(({ type }) => fieldRow.queryType === type) : 
      FIELD_QUERY_TYPES.find(({ type }) => type === "qualifier") // fallback option
    );
    if (localFieldQueryType?.type === "select") {
      localFieldQueryType = { ...localFieldQueryType, options: fieldRow.options };
    }
    return localFieldQueryType;
  }, [fieldRow]);

  const title = useMemo(() => {
    if (fieldRow) {
      return fieldRow.displayPath[fieldRow.displayPath.length - 1] === fieldRow.name
        ? fieldRow.displayPath.join(" > ")
        : [...fieldRow.displayPath, fieldRow.name].join(" > ");
    }
    return id.split("-").join(" > ");
  }, [fieldRow, id]);

  if (!objectFields || objectFields.length === 0) {
    return null;
  }

  return (
    <Stack vertical spacing="tight">
      <Subheading>{title}</Subheading>

      {field.conditions.map((condition, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Stack.Item key={`${id}-${index}`}>
          <FilterCondition
            condition={condition}
            setCondition={(value) =>
              setField({ ...field, conditions: field.conditions.map((el, i) => (i === index ? value : el)).filter(Boolean) })
            }
            fieldQueryType={fieldQueryType}
            sibblingFilterConditions={field.conditions}
            showError={showError}
          />
        </Stack.Item>
      ))}
      <Stack.Item>
        <Stack distribution="trailing">
          <Button
            plain
            onClick={() =>
              setField({
                ...field,
                conditions: [
                  ...field.conditions,
                  { id: +new Date(), operator: id.split("-").pop().match("liquid") ? LIQUID_FILTER_OPERATORS[0] : null },
                ],
              })
            }
          >
            Add Condition
          </Button>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

function TemplateFiltering({ scrollToBottom }) {
  const [{ reportFields, reportFilters }, dispatch, { queryFields, showError }] = useReportTemplate();
  const setReportFilters = useCallback((value) => dispatch({ type: "merge", value: { reportFilters: value } }), [dispatch]);
  const addOptimalFilters = () => dispatch({ type: "addSmartFilters" });

  const setReportFilterByIndex = (field, index) => {
    const result = reportFilters.slice();
    if (field) {
      result.splice(index, 1, field);
    } else {
      result.splice(index, 1);
    }
    setReportFilters(result);
  };

  const optimizeFiltersAvailable = useMemo(() => {
    if (
      reportFields.some(
        (element) =>
          element.id.includes("-fulfillments-") || element.id.includes("-transactions-") || element.id.includes("-refunds-")
      ) &&
      reportFilters.some(
        (filter) => filter.id === "order-updated_at" && filter.conditions.some((condition) => condition.operator === "After Date")
      ) &&
      reportFilters.some(
        (filter) => filter.id === "order-created_at" && filter.conditions.some((condition) => condition.operator === "Before Date")
      )
    ) {
      return false;
    }
    return reportFields.some(
      (element) =>
        element.id.includes("-fulfillments-") || element.id.includes("-transactions-") || element.id.includes("-refunds-")
    );
  }, [reportFields, reportFilters]);

  const lastRenderCountRef = useRef(null);
  useEffect(() => {
    if (reportFilters.length === lastRenderCountRef.current + 1) {
      scrollToBottom?.();
    }
    lastRenderCountRef.current = reportFilters.length;
  }, [reportFilters.length, scrollToBottom]);

  return (
    <Stack vertical>
      {reportFilters.length ? (
        reportFilters.map((field, index) => (
          <FilterRow
            key={`filterRow#${field.id}`}
            field={field}
            setField={(newField) => {
              setReportFilterByIndex(newField, index);
            }}
            objectFields={queryFields}
            showError={showError}
          />
        ))
      ) : (
        <p>Refine the data shown in your report.</p>
      )}

      <Stack>
        <Stack.Item fill>
          <p>All of the above conditions must be met.</p>
        </Stack.Item>
        <Stack.Item>
          <p>
            <Stack alignment="center" spacing="extraTight">
              <Stack.Item>Add new filters using.</Stack.Item>
              <Stack.Item>
                <Icon source={FilterMajor} color="base" />
              </Stack.Item>
              <Stack.Item>in the Shopify Field Browser</Stack.Item>
            </Stack>
          </p>
        </Stack.Item>
        {optimizeFiltersAvailable ? (
          <Stack.Item>
            <Button onClick={addOptimalFilters}>Optimize Filters</Button>
          </Stack.Item>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default TemplateFiltering;
